import Vue from 'vue/dist/vue.js';
import Vuex from 'vuex';

Vue.use(Vuex);

import userModule from './userModule.js';
import userDataModule from './userDataModule.js'

export default new Vuex.Store({
  modules: {
    user: userModule,
    userData: userDataModule,
  }
});
