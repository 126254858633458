module.exports = {
  notifications: 'Notifications',
  mail: 'Mail',
  enable: 'Activer',
  enable_devices: 'Autoriser les nofitications sur cet appareil: ',
  enabled: 'Notifications activés sur votre appareil.',
  not_supported: 'Les notifications ne sont pas disponible sur votre appareil.',
  see_on_vo2fast: 'Voir sur VO2Fast',
  day_workout: 'Séance du jour',
  day_workout_text: 'M\'envoyer la séance d\'entraînement du jour par notification/mail chaque matin.',
  model_change: 'Changement de modèle',
  model_change_text: 'M\'envoyer une notification/mail chaque fois qu\'un nouveau modèle (changement de FTP par exemple) est détecté.',
  subscribed: 'Vous êtes abonné au notifications VO2Fast.',
  ftp_update: 'Nouvelle FTP detecté:  {0}W !',
  map_update: 'Nouvelle PMA detecté: {0}W !',
  pmax_update: 'Nouvelle puissance max detecté: {0}W !',
  hrt_update: 'Nouvelle FC au seuil detecté: {0}bpm !',
  hrmax_update: 'Nouvelle FC max detecté: {0}bpm !',
  power_model_update: 'Nouveau modèle: {threshold_power}W FTP, {map}W PMA, {max_power}W puissance max, {anaerobic_capacity}Kj de capacité anaérobique',
  heart_rate_model_update: 'Nouveau modèle: {threshold_heart_rate}bpm au seuil, {max_heart_rate}bpm fcmax.',
  day_workout_message: 'Votre séance du jour est {workout}.',
  more_details_click_below: 'Pour plus de détails, cliquez sur le lien c-dessous.',
};
