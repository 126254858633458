<!-- Language switch -->
<!-- Allow user to change language of app (and will update settings) -->
<!-- Can be displayed in two forms, either as a list of link for each language or as a select -->
<template>
  <div class="languageswitcher">
    <ul v-if="getInputType=='list'">
      <li class="selectedlanguage"> {{ languages[selected].title }} </li>
      <template v-for="(entry, i) in languages">
        <li :key="entry.title" v-if="i!=selected"><a href="#0" v-on:click="changeLocale(i)"> {{ entry.title }} </a></li>
      </template>
    </ul>
    <div v-if="getInputType=='select'">
      <b-field :label="$t('settings.language')">
        <b-select v-model="selected" v-on:change="changeLocale($event.target.value)">
          <template v-for="(entry, i) in languages">
            <option :value="i" :key="entry.title">{{ entry.title }}</option>
          </template>
        </b-select>
      </b-field>
    </div>
    <div v-if="getInputType=='selectFlag'">
      <SwitchLang @changed="changeLocaleWithLang" :initLang="initLang" :options="languages" />
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/vuei18n.js';
import SwitchLang from "vue-switch-lang";

export default {
  name: 'LanguageSwitch',
  props: ['inputType'],
  computed: {
    getInputType(){ return this.inputType || 'list'; },
    initLang(){
      for(var i=0; i<this.languages.length; i++)
        if(this.languages[i].language == i18n.locale)
          return this.languages[i];

      console.error('init lang unknown ? ', i18n.locale, ' ; ', navigator.language);
      return { flag: 'us', language: 'en', title: 'English'};
    },
  },
  data() {
    return {
      locales: ['en', 'fr'],
      languages: [
        { flag: 'us', language: 'en', title: 'English'},
        { flag: 'fr', language: 'fr', title: 'Français'},
      ],
      selected: 0,
    };
  },
  methods: {
    changeLocaleWithLang(lang){
      this.changeLocale(this.languages.indexOf(lang));
    },
    changeLocale(index){
      this.selected = index;
      var locale = this.languages[index].language;
      i18n.locale = locale;
      this.$store.dispatch('updateLanguage', locale);
      localStorage.setItem('locale', locale);
      document.documentElement.setAttribute('lang', i18n.locale); //update html lang

      const routeBase = this.$route.path.split('/')[1]; //If current route path is a language subfolder (/fr/ or /en/)
      if(this.locales.includes(routeBase) && routeBase !== locale){ //And if its not the current chosen locale
        const path = this.$route.path.split('/').slice(2).join('/');
        this.$router.push('/'+locale+'/'+path); //redirect to correct language route
      }
    }
  },
  mounted(){
    for(var i=0; i<this.languages.length; i++){
      if(this.languages[i].language == i18n.locale){
        this.selected = i;
        break;
      }
    }
  },
  components: {
    SwitchLang,
  },
};
</script>

<style lang="scss" scoped>
</style>
