/*
 * Handle units conversion between metrics and imperial or other
 */
import Vue from 'vue/dist/vue.js';

function roundValue(val, decimals = 1){
  let mul = Math.pow(10, decimals);
  return Math.round(val*mul)/mul;
}

function positive(val){
  return Math.max(val, 0);
}

Vue.mixin({
  methods:{
    distanceUnit(){ return this.$store.getters.settings?.display_units?.distance || 'km'; },
    heightUnit(){ return this.$store.getters.settings?.display_units?.height || 'm'; },
    weightUnit(){ return this.$store.getters.settings?.display_units?.weight || 'kg'; },
    temperatureUnit(){ return this.$store.getters.settings?.display_units?.temperature || 'C'; },

    displayUnit(unit){
      switch(unit){
        case 'distance': return this.distanceUnit();
        case 'height': return this.heightUnit();
        case 'weight': return this.weightUnit();
        case 'temperature': return this.temperatureUnit();
      }
      throw 'Error unknown unit: ' + unit;
    },

    //Convert value to the user system and transform it to a string with the attached unit to display it
    showUnit(val, from, to, fixed, showUnit){
      var result = roundValue(this.$units(val).from(from).to(to), fixed);
      if(showUnit)
        result= result.toString() + to;
      return result;
    },
    showDistance(val, fixed = 1, showUnit = true){ return this.showUnit(val, 'km', this.distanceUnit(), fixed, showUnit); },
    showSpeed(val, fixed = 1, showUnit = true){ return this.showDistance(val, fixed, showUnit) + (showUnit ? '/h' : ''); },
    showHeight(val, fixed = 0, showUnit = true){ return this.showUnit(val, 'm', this.heightUnit(), fixed, showUnit); },
    showWeight(val, fixed = 0, showUnit = true){ return this.showUnit(val, 'kg', this.weightUnit(), fixed, showUnit); },
    showTemperature(val, fixed = 1, showUnit = true){ return this.showUnit(val, 'C', this.temperatureUnit(), fixed, showUnit); },

    //Convert every values to metric system (to store it)
    convertDistance(val){ return positive(this.$units(val).from(this.distanceUnit()).to('km')); },
    convertHeight(val){ return (this.$units(val).from(this.heightUnit()).to('m')); },
    convertWeight(val){ return positive(this.$units(val).from(this.weightUnit()).to('kg')); },
    convertTemperature(val){ return (this.$units(val).from(this.temperatureUnit()).to('C')); },

    convertUnit(val, unit){
      switch(unit){
        case 'distance': return this.convertDistance(val);
        case 'height': return this.convertHeight(val);
        case 'weight': return this.convertWeight(val);
        case 'temperature': return this.convertTemperature(val);
      }
      throw 'Error unknown unit: ' + unit;
    },

    //Convert metric value to user system
    convertDistanceFrom(val){ return positive(roundValue(this.$units(val).from('km').to(this.distanceUnit()))); },
    convertHeightFrom(val){ return roundValue(this.$units(val).from('m').to(this.heightUnit())); },
    convertWeightFrom(val){ return positive(roundValue(this.$units(val).from('kg').to(this.weightUnit()))); },
    convertTemperatureFrom(val){ return roundValue(this.$units(val).from('C').to(this.temperatureUnit())); },

    convertUnitFrom(val, unit){
      switch(unit){
        case 'distance': return this.convertDistanceFrom(val);
        case 'height': return this.convertHeightFrom(val);
        case 'weight': return this.convertWeightFrom(val);
        case 'temperature': return this.convertTemperatureFrom(val);
      }
      throw 'Error unknown unit: ' + unit;
    },



  }
});
