/*
 * Load translations into vue i18n
 */
import Vue from 'vue/dist/vue.js';
import VueI18n from 'vue-i18n';
const english = require('../../shared/translations/english/english.js');
const french = require('../../shared/translations/french/french.js');

Vue.use(VueI18n);

const translations = {
  en: english,
  fr: french
};

//Get locale from local storage if its defined
var locale = localStorage.getItem('locale');

if(!locale){ //If not try to read the locale from the navigator and store it in localstorage
  locale = navigator.language.split('-')[0] || 'en';
  localStorage.setItem('locale', locale);
}

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages: translations
});

document.documentElement.setAttribute("lang", i18n.locale); //update html lang


export default i18n;
