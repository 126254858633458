/*
 * Index, will load routes into vue router
 */
import Vue from 'vue/dist/vue.js';
import VueRouter from 'vue-router';
import store from '@/stores/store.js';
import routes from './routes.js';

Vue.use(VueRouter);

//Create router and load routes
const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };     // always scroll to top
  },
});

//Middleware before each routes, check that user is logged if necessary (meta requires Auth)
//If user not logged and it's needed, redirect him to the login page
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)){
    if(store.getters.isLoggedIn){
      return next();
    }
    localStorage.setItem('loginRedirection', to.path);
    next('/login');
  }else{
    next();
  }
});

export default router;
