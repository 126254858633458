import axios from 'axios';
import Vue from 'vue/dist/vue.js';
import i18n from '@/plugins/vuei18n.js';
import { LocalDate } from '@/helpers/dateUtil.js';
import { post, get } from '@/plugins/network.js';



function fill(obj, prop, val){
  if(obj[prop] == undefined){
    obj[prop] = val;
    return true;
  }
  return false;
}

function fillDisplayUnits(settings){
  var filled = false;
  var country = navigator.language.split('-')[1]
  var isMetrics = (country != 'US' && country != 'GB' && country != 'MM' && country != 'LR');

  filled |= fill(settings, 'display_units', {});
  var display_units = settings.display_units;
  filled |= fill(display_units, 'distance', isMetrics ? 'km' : 'mi');
  filled |= fill(display_units, 'height', isMetrics ? 'm' : 'ft');
  filled |= fill(display_units, 'weight', isMetrics ? 'kg' : 'lb');
  filled |= fill(display_units, 'temperature', isMetrics ? 'C' : 'F');
  return filled;
}

function fillNotifications(settings){
  var filled = false;
  filled |= fill(settings, 'notifications', {});
  var notifications = settings.notifications;
  filled |= fill(notifications, 'day_workout_notification', false);
  filled |= fill(notifications, 'day_workout_mail', false);
  filled |= fill(notifications, 'model_change_notification', false);
  filled |= fill(notifications, 'model_change_mail', false);
  return filled;
}


function fillSettings(settings){
  var filled = false;
  filled |= fill(settings, 'location', { name: '', lat: 0, lon: 0 });
  filled |= fillDisplayUnits(settings);
  filled |= fillNotifications(settings);
  filled |= fill(settings, 'language', i18n.locale);

  if(settings.language !== i18n.locale)
    i18n.locale = settings.language;

  //Check if timezone offset have changed
  if(settings.timezone_offset !== new Date().getTimezoneOffset()){
    settings.timezone_offset = new Date().getTimezoneOffset();
    filled = true;
  }

  return filled;
}



function fillInfos(infos){
  var filled = false;
  filled |= fill(infos, 'equipment', { heart_rate_monitor: false, power_meter: false, home_trainer_power: false });
  return filled;
}

//Fill user data with default values when not present (for when new settings are added)
function fillUser(user){
  user.settings = user.settings || {};
  var filled = fillSettings(user.settings);
  //If any change after fill, notify server
  if(filled)
    post('/update_settings', { settings: user.settings });

  if(user.infos.birthday)
    user.infos.birthday = new Date(user.infos.birthday);
  filled = fillInfos(user.infos);

  if(filled)
    post('/update_user_infos', { infos: user.infos });

  user.business = user.business || {};

  return user;
}

const userModule = {
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : null
  },
  mutations: {
    auth_request(state){
      state.status = 'loading';
    },
    auth_success(state, token){
      state.status = 'success';
      state.token = token;
    },
    auth_error(state){
      state.status = 'error';
    },
    set_user(state, user){
      state.user = fillUser(user);
    },
    set_settings(state, settings){
      Vue.set(state.user, 'settings', settings);
    },
    set_onboarding(state, onboarding){
      Vue.set(state.user, 'onboarding', onboarding);
    },
    set_username(state, username){
      Vue.set(state.user, 'username', username);
    },
    set_user_infos(state, infos){
      Vue.set(state.user, 'infos', infos);
    },
    set_training_plan_data(state, training_plan_data){
      Vue.set(state.user, 'training_plan_data', training_plan_data);
    },
    set_business(state, business){
      Vue.set(state.user, 'business', business);
    },
    logout(state){
      state.status = '';
      state.token = '';
      state.user = {};
    },
  },
  actions: {
    async login({commit}, userdata){
      try{
        commit('auth_request');
        var data = await post('/login', userdata);
        localStorage.setItem('token', data.token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
        commit('auth_success', data.token);
        commit('set_user', data.user);
        return data;
      }catch(err){
        commit('auth_error');
        localStorage.removeItem('token');
        throw err;
      }
    },

    async loadUser({commit}){
      var data = await get('/user');
      if(data && data.user){
        commit('set_user', data.user);
        return data.user;
      }
      return null;
    },

    async updateSettings({commit}, settings){
      var data = await post('/update_settings', { settings: settings });
      commit('set_settings', settings);
    },

    async updateOnboarding({commit}, onboarding){
      var data = await post('/update_onboarding', { onboarding: onboarding });
      commit('set_onboarding', onboarding);
    },

    updateLanguage({commit, getters, dispatch}, language){
      if(getters.isLoggedIn){
        var settings = getters.settings;
        settings.language = language;
        dispatch('updateSettings', settings);
      }
    },

    async updateUsername({commit}, username){
      var data = await post('/update_username', { username: username });
      commit('set_username', username);
    },
    async updateUserInfos({commit}, infos){
      await post('/update_user_infos', { infos : infos });
      commit('set_user_infos', infos);
    },

    async updateBusiness({commit}, business){
      var data = await post('/update_business_data', { business: business });
      commit('set_business', business);
    },

    logout({commit}){
      commit('resetData');
      commit('logout');
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      commit('setLoadedUser', null);
      commit('set_user', null);
    },
  },
  getters : {
    locale: state => state.locale,
    token: state => state.token,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    settings: state => state.user?.settings || null,
    infos: state => state.user?.infos || null,
    business: state => state.user?.business || null,
    onboarding: state => state.user?.onboarding,
    hasSync: state => state.user?.syncs?.garmin || state.user?.syncs?.strava,
    isBaseAccount: state => state.user?.account_type === 'BASE',
    isBusinessAccount: state => state.user?.account_type === 'BUSINESS',
    hasStandardAccess: state => ['SUPERADMIN', 'ADMIN', 'COACH', 'TESTER', 'PREMIUM', 'STANDARD'].includes(state.user?.account_type),
    hasPremiumAccess: state => ['SUPERADMIN', 'ADMIN', 'COACH', 'TESTER', 'PREMIUM'].includes(state.user?.account_type),
    hasTesterAccess: state => ['SUPERADMIN', 'ADMIN', 'TESTER'].includes(state.user?.account_type),
    hasCoachAccess: state => ['SUPERADMIN', 'ADMIN', 'COACH'].includes(state.user?.account_type),
    hasBusinessAccess: state => ['BUSINESS', 'ADMIN', 'SUPERADMIN'].includes(state.user?.account_type),
    hasAdminAccess: state => state.user?.account_type === 'ADMIN' || state.user?.account_type === 'SUPERADMIN',
    hasSuperAdminAccess: state => state.user?.account_type === 'SUPERADMIN',
    hasTrial: state => state.user?.has_trial,
  }
};

export default userModule;
