module.exports = `<h1>Politique de confidentialité de VO2Fast</h1>
  <p>Cette page est utilisée pour informer les visiteurs du site web de nos politiques en matière de collecte, d'utilisation et de divulgation des informations personnelles si quelqu'un décide d'utiliser notre service: le site web VO2Fast.</p>
  <p>Si vous choisissez d'utiliser notre Service, alors vous acceptez la collecte et l'utilisation des informations en relation avec cette politique. Les informations personnelles que nous recueillons sont utilisées pour fournir et améliorer le Service. Nous n'utiliserons ni ne partagerons vos informations avec quiconque, sauf dans les cas décrits dans la présente politique de confidentialité.</p>

  <h2>Collecte et utilisation des informations</h2>
  <p>Dans le cadre de votre utilisation des Services, nous pouvons recueillir les " Données personnelles " suivantes auprès de vous si vous choisissez de les fournir, y compris (mais sans s'y limiter) :</p>
  <ul>
    <li>Votre adresse email, votre addresse et d'autres informations de contact</li>
    <li>Les données importées de tiers, telles que la fréquence cardiaque ou d'autres données de réponse physique que vous choisissez d'importer</li>
    <li>Votre nom d'utilisateur, votre mot de passe, votre photo de profil, votre sexe, votre poids actuel, votre préférence d'affichage, votre fréquence cardiaque au repos, votre fréquence cardiaque maximale, votre fréquence cardiaque au seuil, votre puissance au seuil, votre puissance maximale, votre capacité anaérobie.</li>
  </ul>

  <h2>Google Analytics</h2>
  <p>Nous utilisons Google Analytics, un service d'analyse Web fourni par Google, Inc.  Google Analytics recueille des informations telles que la fréquence à laquelle les utilisateurs visitent ce site, les pages qu'ils visitent lorsqu'ils le font et les autres sites qu'ils ont utilisés avant de venir sur ce site. Nous utilisons les informations que nous obtenons de Google Analytics uniquement pour améliorer ce site. Google Analytics peut recueillir des informations telles que votre adresse IP, l'heure de votre visite, si vous êtes un visiteur récurrent et tout site Web de référence. Google Analytics ne collecte pas d'informations permettant de vous identifier personnellement. Les informations générées par Google Analytics seront transmises et stockées par Google et seront soumises aux règles de Google et à sa <a href="https://policies.google.com/privacy?hl=en">politique de confidentialité</a>. Pour en savoir plus sur les services des partenaires de Google et pour connaître les modalités de désactivation du suivi des analyses par Google, cliquez <a href="https://policies.google.com/technologies/partner-sites">ici</a>.</p>

  <h2>Fournisseurs de services</h2>
  <p>Nous sommes susceptibles d'employer des sociétés et des personnes tierces pour les raisons suivantes :</p>
  <ul>
    <li>Pour faciliter notre service ;</li>
    <li>Pour fournir le Service en notre nom ;</li>
    <li>Pour exécuter des services liés au Service ; ou</li>
    <li>Pour nous aider à analyser la façon dont notre Service est utilisé.</li>
  </ul>
  <p>Nous souhaitons informer les utilisateurs de nos services que ces tiers ont accès à vos informations personnelles. La raison en est l'exécution des tâches qui leur sont assignées en notre nom. Cependant, ils sont tenus de ne pas divulguer ou utiliser ces informations à d'autres fins.</p>

  <h2>Sécurité</h2>
  <p>Nous apprécions la confiance que vous nous accordez en nous fournissant vos informations personnelles et nous nous efforçons donc d'utiliser des moyens commercialement acceptables pour les protéger. Mais n'oubliez pas qu'aucune méthode de transmission sur Internet, ni aucune méthode de stockage électronique n'est sûre et fiable à 100 %, et que nous ne pouvons pas en garantir la sécurité absolue.</p>

  <h2>Liens vers d'autres sites</h2>
  <p>Notre service peut contenir des liens vers d'autres sites. Si vous cliquez sur un lien tiers, vous serez dirigé vers ce site. Notez que ces sites externes ne sont pas exploités par nous. Par conséquent, nous vous conseillons vivement de consulter la politique de confidentialité de ces sites. Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou service tiers.</p>

  <h2>Confidentialité des enfants</h2>
  <p>Nos services ne s'adressent pas aux personnes âgées de moins de 13 ans. Nous ne recueillons pas sciemment d'informations personnelles identifiables auprès d'enfants de moins de 13 ans. Dans le cas où nous découvrons qu'un enfant de moins de 13 ans nous a fourni des informations personnelles, nous les supprimons immédiatement de nos serveurs. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des informations personnelles, veuillez nous contacter afin que nous puissions faire les actions nécessaires.</p>

  <h2>Changements à cette politique de confidentialité</h2>
  <p>Nous sommes susceptibles de mettre à jour notre politique de confidentialité de temps à autre. Ainsi, nous vous conseillons de consulter cette page périodiquement pour prendre connaissance de tout changement. Nous vous informerons de toute modification en publiant la nouvelle politique de confidentialité sur cette page. Ces modifications entrent en vigueur immédiatement, après leur publication sur cette page.</p>

  <h2>Nous contacter</h2>
  <p>Si vous avez des questions ou des suggestions à propos de notre politique de confidentialité, n'hesitez pas à nous contacter via notre email: contact@vo2fast.com</p>

`;
