module.exports = {
  name: {
    '20min': '20min test',
    '5min': '5min test',
    '1min': '1min test',
    'sprintsTest': 'Sprints tests',
    'opener': '{0} Opener',
    'recovery': '{0} recovery',
    'light': '{0} light',
    'fundamental_endurance': 'Fundamental endurance {0}',
    'active_endurance': 'Active endurance {0}',
    'endurance_sprints': 'Endurance + sprints {0}',
    'endurance_strength': 'Endurance strength {0}',
    'tempo': 'Tempo {0}',
    'tempo_strength': 'Tempo strength {0}',
    'threshold': 'Threshold {0}',
    'threshold_strength': 'Threshold strength {0}',
    'short_MAP': 'Short MAP',
    'vo2max': 'VO2Max',
    'lactic': 'Lactic',
    'max_strength': 'Max strength sprints',
  },
  description: {
    '20min': `20-minute test that will be used to estimate your threshold power (FTP). \n
      Do a maximum effort for 20 minutes while trying to maintain a constant power level. It is better to start a little faster and then have to slow down than to do this test progressively which does not define your maximum potential.`,

    '5min': `5-minute test that will be used to estimate your maximum aerobic power (MAP). \n
      Do a maximum effort for 5 minutes. The goal is to try to maintain the highest power level consistently.`,

    '1min': `1 minute test, make a maximum effort for this duration. \n
      The aim of this test is to establish specific points in your record power profile (PPR), which is essential for monitoring your progress in the various energy systems.`,

    'sprintsTest': `Tests on 30 second sprints at maximum intensity. \n
      In order to guarantee the best performance, the choice of gear ratio is essential. It is advisable to find the best ratio that will allow you to reach your maximum power in the first few seconds and then try to maintain this high power as much as possible.`,

    'opener': `A short {0} opener to get your body ready for tomorrow's race. \n
      A ride to test your race gear, check that everything is working well on your bike and a few sprints to prepare your legs for tomorrow's race.`,

    'recovery': `Recovery session in order to recover from the previous days' intensities. It is important to be able to stay in the target zone to recover as well as possible for the next sessions.`,
    'light': `Recovery session in order to recover from the previous days' intensities. It is important to be able to stay in the target zone to recover as well as possible for the next sessions.`,

    'fundamental_endurance': `Endurance workout, the goal is to keep the pace as constant as possible throughout. \n
      Endurance work improves several factors that are essential for a good physical condition and any further progression of the muscular and pulmonary system. It is the priority work base to allow development.`,

    'active_endurance': `Endurance workout with variations in pace. The goal is to spend time in the high endurance zone interspersed with lower intensity recovery zones. \n
      Active endurance work is a specific work zone based on the high endurance zone to maximize physiological gains and avoid monotony. It is based on a variation of duration and power during the zone but always in the endurance zone. \n
      This pace is the foundation of the entire preparation. Without having been developed correctly, it will be difficult for you to evolve favorably at higher paces.`,


    'endurance_sprints': `Endurance session with {sprints} short sprints. It's a combination of low intensity work coupled with Pmax sprints of less than 8 seconds duration to work on the ability to change pace and sprint, without creating metabolic waste.`,

    'endurance_strength': `Endurance zone session with variations in rhythm and cadence. The strength work is done at a pace below 60rpm and at a higher pace during the recovery phases. \n
      The objective is to work at the muscular level and develop its qualities in strength endurance. Be careful, you should never go below 50rpm in order to avoid excessive stress on the knees.`,

    'tempo': `Workout with {intervals} in the tempo zone. This zone is just below your threshold and allows the development of fundamental qualities that will allow you to progress. It is important to stay in this zone and not fall into the threshold zone.`,

    'tempo_strength': `Workout with {intervals} in tempo zone with strength work at 60rpm.`,

    'threshold': `Workout with {intervals} at threshold. This zone will be close to your racing intensity. However, it is not advisable to spend too much time in this zone because it does not allow much development of physiological qualities but creates fatigue. \n
      This threshold represents a limit for which and beyond which equilibriums tip over (ventilation, lactic acid production, etc.). This pace is close to the competition pace; I can no longer follow a conversation, at most a word from time to time.`,

    'threshold_strength': `Workout with {intervals} at threshold with a cadence between 55 and 60rpm. This specific zone will be close to your race intensity with the constraint of strength in order to strengthen your muscle fibers. \n
      This threshold represents a limit for which and beyond which equilibriums tip over (ventilation, lactic acid production, etc.). This pace is close to the competition pace; I can no longer follow a conversation, at most a word from time to time.`,

    'short_MAP': `Intervals session ({intervals}) where we alternate short periods of high intensity and recovery. The session begins with a bout of endurance to activate the aerobic system. \n`,

    'vo2max': `Session with short intervals of {intervals} at high intensity. The session begins with a bout of endurance to activate the aerobic system. \n
      VO2max is the work intensity that you produce during an effort whose energy expenditure corresponds to the maximum oxygen consumption. I am hyperventilating, I can only sustain the effort for a few minutes at most. This power can be maintained for approximately 3 to 7 minutes. The work is done in intervals in durations of between 1'30 and 4' with long recovery periods in order to be able to maintain the intensities and not go into a glycolytic training.`,

    'lactic': `This workout will make your legs hurt! The goal is clear, to develop maximum power on each of the repetitions. The purpose is to work on your capacity to absorb lactic acid. A long recovery time at low intensity between each intervals is essential in order to be able to give your maximum during each repetition.`,

    'max_strength': `The workout that will break your muscle fiber! The goal will be to sprint while giving your maximum with the gear. A tip: do the sprint on a bump with a maximum of 6%, it is essential to put all your strength at the beginning of the sprint. A long recovery time at low intensity between each intervals is essential in order to be able to give your maximum during each repetition.`,

    'sprints': `Workout with sprint at maximum intensity. \n
      In order to guarantee the best performance, the choice of gear ratio is essential. It is advisable to find the best ratio that will allow you to reach your maximum power in the first few seconds and then try to maintain this high power as much as possible.`,

    'sst': `Sweet Spot Training (SST) is a highly effective and strategic approach that focuses on optimizing the intensity of your training to maximize performance gains. \n This method falls between traditional moderate-intensity training and high-intensity intervals, targeting a specific intensity range where the body efficiently utilizes oxygen while minimizing fatigue.`,
  },

  steps_descriptions: {
    'progressive_warmup': `Progressive warmup where you slowly ramp up in intensity.`,
    '1min_test': `1 minute max effort, give your all !`,
    '5min_test': `5 minutes max effort, give your all !`,
    '20min_test': `20 minutes max effort, maintain the highest average power you can sustain for 20 minutes. Be careful to not start too fast... Give your all !`,
    'light_endurance': `Light endurance pace.`,
    'recovery_workout': `Very light pace in recovery zone.`,
    'cooldown': `Cooldown.`,
    'lactic': `{nb_intervals} long sprints of {intervals_duration} at max intensity with {recovery}  recovery between each sprint. Give it all away every time!`,
    'enduranceF': `Endurance pace for long ride.`,
    'active_endurance': `Endurance pace for long ride.`,
    'endurance_ramp': `Long endurance warm-up with gradual, gentle increase in intensity.`,
    'endurance_sprints': `{sprints} short max sprints of 7s with 5min recovery in between.`,
    'endurance_strength': `{intervals} at endurance pace with a 60rpm cadence with {recovery} recovery inbetween.`,
    'tempo': `{intervals} at tempo pace with {recovery} recovery inbetween.`,
    'tempo_strength': `{intervals} at tempo pace with a 60rpm cadence with {recovery} recovery inbetween.`,
    'threshold': `{intervals} at threshold pace with {recovery} recovery inbetween.`,
    'threshold_strength': `{intervals} at threshold pace with a 60rpm cadence with {recovery} recovery inbetween.`,
    'progressive_warmup_HIT': `Progressive warmup that finishes with 3 15s sprints.`,
    'recovery': `Recovery`,
    'short_MAP': `Intervals of {intervals} at VO2Max intensity.`,
    'vo2max': `{intervals} in VO2Max zone (very hard pace) with {recovery} recovery between each intervals.`,
    'sprints': `{nb_sprints} sprints of {sprints_duration} at max intensity with 5min recovery between each sprints. Give it all away every time!`,
    'max_strength': `{nb_sprints} sprints of {sprints_duration} at max intensity pushing big gears with ~4min recovery between each sprints. Give it all away every time!`,
    'opener': `1min at VO2Max followed by 3 shorts max sprints of 6s.`,
  },
};
