module.exports = {
  name: {
    '20min': 'Test de 20min',
    '5min': 'Test de 5min',
    '1min': 'Test de 1min',
    'sprintsTest': 'Tests de sprints',
    'opener': 'Déblocage {0}',
    'recovery': '{0} récupération',
    'light': '{0} light',
    'fundamental_endurance': 'Endurance fondamentale {0}',
    'active_endurance': 'Endurance active {0}',
    'endurance_sprints': 'Endurance + sprints {0}',
    'endurance_strength': 'Endurance force {0}',
    'tempo': 'Tempo {0}',
    'tempo_strength': 'Tempo force {0}',
    'threshold': 'Seuil {0}',
    'threshold_strength': 'Seuil force {0}',
    'short_MAP': 'PMA courte',
    'vo2max': 'VO2Max',
    'lactic': 'Lactique',
    'max_strength': 'Sprints force max',
  },
  description: {
    '20min': `Test de 20 minutes qui sera utilisé pour estimer votre puissance au seuil (FTP). \n
      Faites un effort maximum pendant 20 minutes en essayant de maintenir un niveau de puissance constante. Il est préférable de partir un peu plus vite puis de devoir ralentir plutôt que de faire ce test en progressif qui ne définit pas votre potentiel maximal.`,

    '5min': `Test de 5 minutes qui sera utilisé pour estimer votre puissance maximal aérobie (PMA). \n
      Faites un effort maximum pendant 5 minutes. L’objectif est d’essayer de maintenir le niveau de puissance le plus élevé de façon constante. `,

    '1min': `Test de 1 minute, faites un effort maximal sur cette durée. \n
      L’objectif de ce test est d’établir des points spécifiques dans votre profil puissance record (PPR) indispensable pour pouvoir suivre votre progression dans les différentes filières énergétiques.`,

    'sprintsTest': `Tests sur des sprints de 30 secondes à intensité maximale. \n
      Afin de garantir la meilleure performance, le choix du braquet est indispensable. Il est opportun de trouver le meilleur rapport afin de pouvoir atteindre votre puissance maximale sur les premières secondes puis d’essayer de maintenir au maximum cette puissance élevée.`,

    'opener': `Déblocage rapide de {0} pour activer ton organisme et préparer l’échéance de demain. \n
      Une sortie afin de pouvoir tester ton matériel de course, vérifier que tout fonctionne bien sur ton vélo et quelques sprints pour tester tes jambes pour la course de demain.`,

    'recovery': `Séance de récupération afin de pouvoir récupérer des intensités des jours précédents. Il est important de pouvoir rester dans la zone cible pour récupérer au mieux au vu des prochaines séances.`,
    'light': `Séance de récupération afin de pouvoir récupérer des intensités des jours précédents. Il est important de pouvoir rester dans la zone cible pour récupérer au mieux au vu des prochaines séances.`,

    'fundamental_endurance': `Séance en zone d’endurance, le but est de garder une allure la plus constante possible tout le long. \n
      Le travail de l’endurance permet l’amélioration de plusieurs facteurs essentiels à une bonne condition physique et à toute progression ultérieure du système musculaire et pulmonaire. C’est le socle de travail prioritaire pour pouvoir permettre le développement. `,

    'active_endurance': `Séance en zone d’endurance avec variations de rythme. Le but est de passer du temps en zone haute d’endurance entrecoupée de zone de récupération à plus basse intensité. \n
      Le travail de l’endurance active est une zone de travail spécifique basée sur la zone haute d’endurance afin de maximiser les gains physiologiques et éviter la monotonie. Elle est basée sur une variation de durée et de puissance durant la zone mais toujours dans la zone d’endurance. \n
      Cette allure constitue les fondations de toute une préparation. Sans avoir été développée correctement, il te sera difficile d'évoluer favorablement aux allures supérieures. Elle se travaille lors des séances d'entraînement faciles (je peux encore parler et je peux encore contrôler aisément mon geste, mon relâchement...).`,

    'endurance_sprints': `Séance d'endurance avec {sprints} sprints court. C'est une combinaison de travail de basse intensité couplé à des sprints Pmax de durées inférieures à 8 secondes afin de travailler la capacité à changer de rythme et le sprint, sans créer de déchets musculaire.`,

    'endurance_strength': `Séance en zone d’endurance avec variations de rythme et de cadence. Le travail de force se réalise avec des cadences inférieures à 60rpm et une cadence supérieure lors des phases de récupération. \n
      L’objectif est de travailler au niveau musculaire et développer ses qualités en endurance de force. Attention, il ne faut jamais descendre en dessous de 50rpm afin d’éviter des contraintes trop importantes au niveau des genoux.`,

    'tempo': `Séance avec {intervals} en zone de tempo. Cette zone est juste en dessous de votre seuil et permet le développement de qualités fondamentales vous permettant de progresser. Il est important de bien rester dans cette zone et ne pas tomber dans la zone de seuil.`,

    'tempo_strength': `Séance avec {intervals} en zone de tempo avec un travail en force à 60rpm.`,

    'threshold': `Séance avec {intervals} au seuil. Cette zone spécifique se rapprochera de vos intensités en course. Néanmoins, il n’est pas opportun d’y consacrer trop de temps car elle ne permet pas le développement des qualités physiologiques mais crée de la fatigue. \n
      Ce seuil représente une limite pour laquelle et au-delà de laquelle des équilibres basculent (la ventilation, la production d'acide lactique,...). Cette allure est proche de l’allure de compétition ; je ne peux plus suivre une conversation, à la limite un mot de temps en temps. A partir de cette zone de seuil, il est nécessaire de travailler par intervalles.`,

    'threshold_strength': `Séance avec {intervals} au seuil avec une cadence entre 55 et 60rpm. Cette zone spécifique se rapprochera de vos intensités en course avec la contrainte de la force afin de renforcer vos fibres musculaires. \n
      Ce seuil représente une limite pour laquelle et au-delà de laquelle des équilibres basculent (la ventilation, la production d'acide lactique,...). Cette allure est proche de l’allure de compétition ; je ne peux plus suivre une conversation, à la limite un mot de temps en temps. A partir de cette zone de seuil, il est nécessaire de travailler par intervalles.`,

    'short_MAP': `Séance de fractionnés ({intervals}) où on alterne haute intensité et périodes de récupération. La séance commence avec une période d’endurance pour activer le système aérobie. \n`,

    'vo2max': `Séance avec intervalles courts de {intervals} à haute intensité. La séance commence avec une période d’endurance pour activer le système aérobie. \n
      La VO2max est l'intensité de travail que l'on développe au cours d'un effort dont la dépense énergétique correspond à la consommation d'oxygène maximale. Je suis en hyperventilation, je ne peux soutenir l'effort que quelques minutes au maximum. Cette puissance, peut se maintenir environ entre 3 et 7 minutes. Le travail s'effectue par intervalles sur des durées comprises entre 1’30 et 4’ avec de longue période de récupération afin de pouvoir maintenir les intensités et ne pas passer dans un entraînement glycolytique.`,

    'lactic': `La séance qui va faire mal aux jambes ! L’objectif est clair, développer le maximum de puissance sur chacune des répétitions. Le but est de travailler la capacité à encaisser le lactique. Une durée de récupération importante à basse intensité entre chaque session est indispensable afin de pouvoir donner son maximum lors de chaque répétition.`,

    'max_strength': `La séance qui va casser de la fibre musculaire ! L’objectif va être de sprinter en donnant son maximum avec du braquet. Un conseil : réaliser la séance dans une bosse avec un maximum de 6%, il est indispensable de mettre toute sa force dès le début du sprint. Une durée de récupération importante à basse intensité entre chaque session est indispensable afin de pouvoir donner son maximum lors de chaque répétition.`,

    'sprints': `Séance de sprints à intensité maximale. \n
      Afin de garantir la meilleure performance, le choix du braquet est indispensable. Il est opportun de trouver le meilleur rapport afin de pouvoir atteindre votre puissance maximale sur les premières secondes puis d’essayer de maintenir au maximum cette puissance élevée.`,

    'sst': `L'entraînement Sweet Spot Training (SST) est une approche hautement efficace et stratégique qui se concentre sur l'optimisation de l'intensité de votre entraînement pour maximiser les gains de performance. \n Cette méthode se situe entre l'entraînement traditionnel à intensité modérée et les intervalles à haute intensité, ciblant une plage d'intensité spécifique où le corps utilise efficacement l'oxygène tout en minimisant la fatigue.`,
  },

  steps_descriptions: {
    'progressive_warmup': `Echauffement progressif.`,
    '1min_test': `Effort maximal d'une minute, donne tout !`,
    '5min_test': `Effort maximal de 5 minutes, donne tout !`,
    '20min_test': `Effort maximal de 20 minutes, maintient la plus hautes puissance durant 20 minutes. Attention de ne pas partir trop vite… Donnes ton maximum !`,
    'light_endurance': `Allure d'endurance légère.`,
    'recovery_workout': `Allure trés lègere en zone de récup.`,
    'cooldown': `Retour au calme en récup pour finir la séance.`,
    'lactic': `{nb_intervals} sprints longs de {intervals_duration} à intensité max avec {recovery} de récup entre chaque sprints. Donne tout à chaque fois !`,
    'enduranceF': `Allure d’endurance pour des sorties longues.`,
    'active_endurance': `Allure d’endurance pour des sorties longues.`,
    'endurance_ramp': `Echauffement long en endurance avec augmentation progressive et légère de l'intensité.`,
    'endurance_sprints': `{sprints} sprints max courts de 7s avec 5min de récup entre chaque sprints.`,
    'endurance_strength': `{intervals} en endurance en force à une cadence de 60rpm avec {recovery} de récup entre.`,
    'tempo': `{intervals} en tempo (allure modéré) avec {recovery} de récup entre.`,
    'tempo_strength': `{intervals} en tempo (allure modéré) en force à une cadence de 60rpm avec {recovery} de récup entre.`,
    'threshold': `{intervals} au seuil (allure soutenue type course) avec {recovery} de récup entre.`,
    'threshold_strength': `{intervals} au seuil (allure soutenue type course) en force à une cadence de 60rpm avec {recovery} de récup entre.`,
    'progressive_warmup_HIT': `Échauffement progressif avec 3 sprints courts de 15s pour finir.`,
    'recovery': `Récupération`,
    'short_MAP': `Fractionnés de {intervals} à PMA.`,
    'vo2max': `{intervals} en zone de VO2Max (allure trés soutenue) avec {recovery} de récup entre chaque intervals.`,
    'sprints': `{nb_sprints} sprints de {sprints_duration} à intensité max avec 5min de récup entre chaque sprints. Donne tout à chaque fois !`,
    'max_strength': `{nb_sprints} sprints de {sprints_duration} à intensité max en force avec ~4min de récup entre chaque sprints. Donne tout à chaque fois !`,
    'opener': `1min à PMA puis 3 sprints max courts de 6s pour débloquer les jambes.`,
  },
};
