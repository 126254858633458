/*
 * Functions to do post/get request with API server
 */
import axios from 'axios';
import Vue from 'vue/dist/vue.js';

const BASE_URL = process.env.VUE_APP_API_URL;

//Post data on given url and return the response (or throw error if an error is returned)
export async function post(url, data, headers = {}){
  try{
    var response = await axios.post(BASE_URL+url, data, { headers: headers });
    if(response.response != undefined && response.response.status >= 400){
      throw response.response.data.message;
    }
    return response.data;
  }catch(error){
    if(!error) //axios trick ? (https://github.com/axios/axios/issues/960)
      console.error(JSON.parse(JSON.stringify(error)));
    console.error(error);
    throw error;
  }
}


//Get data from given url (or throw error if an error is returned)
export async function get(url, headers = {}){
  try{
    var response = await axios.get(BASE_URL+url, { headers: headers });
    if(response.response != undefined && response.response.status >= 400){
      throw response.response.data.message;
    }
    return response.data;
  }catch(error){
    if(!error) //axios trick ? (https://github.com/axios/axios/issues/960)
      console.error(JSON.parse(JSON.stringify(error)));
    console.error(error);
    throw error;
  }
}

Vue.mixin({ methods: { post, get } });
