<!-- Footer of app -->
<template>
  <footer id="footer" class="footer" v-if="isVisible">
    <div class="container m-auto columns is-mobile is-multiline">
      <div class="column has-text-left-tablet is-full-mobile">
        <p><router-link to="/">
          <img src="@/../public/logo2.png" alt="VO2Fast" width="120"/>
        </router-link></p>
        <p>Copyright© {{ new Date().getFullYear() }} VO2Fast</p>
        <p class="mt-5">
          <b-button href="https://www.facebook.com/VO2Fast-101721752122177" title="facebook" type="is-primary" tag="a" target="_blank" rel="noopener noreferrer" class="p-1 is-rounded h-2 w-2 mr-2">
            <img src="~@/assets/icons/facebook.svg" width="32" class="custom-icon mt-1"/>
          </b-button>
          <b-button href="https://www.instagram.com/vo2fast/" title="instagram" type="is-primary" tag="a" target="_blank" rel="noopener noreferrer" class="p-1 is-rounded h-2 w-2 mr-2">
            <img src="~@/assets/icons/instagram.svg" width="32" class="custom-icon mt-1"/>
          </b-button>
          <b-button href="https://www.linkedin.com/company/vo2fast/" title="linkedin" type="is-primary" tag="a" target="_blank" rel="noopener noreferrer" class="p-1 is-rounded h-2 w-2 mr-2">
            <img src="~@/assets/icons/linkedin.svg" width="32" class="custom-icon mt-1"/>
          </b-button>
          <b-button href="https://www.youtube.com/channel/UC_j2qRTuLVmksR7fmeSmQbw" title="youtube" type="is-primary" tag="a" target="_blank" rel="noopener noreferrer" class="p-1 is-rounded h-2 w-2 mr-2">
            <img src="~@/assets/icons/youtube.svg" width="32" class="custom-icon mt-1"/>
          </b-button>

          <!--         <li><a :href="$t('whatsapp_link')"><b-icon icon="whatsapp"/> Whatsapp</a></li> -->
        </p>
      </div>
      <ul class="column">
        <li><router-link to="/privacy_policy">{{ $t('nav.privacy_policy') }}</router-link></li>
        <li><router-link to="/terms">{{ $t('nav.terms') }}</router-link></li>
        <li><router-link to="/legal">{{ $t('nav.legal') }}</router-link></li>
        <li><router-link to="/contact">{{ $t('nav.contact') }}</router-link></li>
      </ul>
      <LanguageSwitch class="column"/>
    </div>
  </footer>
</template>

<script>
import LanguageSwitch from './LanguageSwitch.vue';

export default {
  name: 'footercomponent',
  components: {
    LanguageSwitch
  },
  computed: {
    isVisible(){ return this.$route.path !== '/offline'; },
  },
}
</script>

<style lang="scss">
#footer{
  margin-top: 20px;
  margin-bottom: 0px;
  color: grey;
}

#footer a{
  color: #EEEEEE;
}

.custom-icon{
  filter: invert(1);
}
</style>
