const englishNotifications = require('./englishNotifications');
const englishDoc = require('./englishDoc');
const englishWorkouts = require('./englishWorkouts');
const englishMails = require('./englishMails');
const englishPrivacyPolicy = require('./englishPrivacyPolicy');
const englishTerms = require('./englishTerms');
const englishLegal = require('../french/frenchLegal');
const englishLanding = require('./englishLanding');
const englishFAQ = require('./englishFAQ');

module.exports = {
  lang: 'en',
  nav: {
    login: 'Login',
    signup: 'Sign Up',
    logout: 'Logout',
    dashboard: 'DashBoard',
    calendar: 'Calendar',
    performances: 'Performances',
    settings: 'Settings',
    privacy_policy: 'Privacy policy',
    terms: 'Terms of use',
    legal: 'Mentions légales',
    workout_creator: 'Workout creator',
    contact: 'Contact',
    help: 'Help',
    community: 'Community',
    blog: 'Blog',
    roadmap: 'Roadmap',
    forum: 'Forum',
    team: 'Team',
    features: 'Features',
    faq: 'FAQ',
    members: 'Members',
    site_map: 'Site Map',
    business_space: 'Business space',
  },
  account: {
    profile: 'Profile',
    your_profile: 'Your profile',
    complementary_informations: 'Complementary informations',
    signup: 'Sign Up',
    create_vo2fast_account: 'Create a VO2Fast account and manage your goals and your sports schedule quickly and easily !',
    signup_now: 'Sign up now',
    login: 'Login',
    to_login: 'Login',
    not_signed_up: 'Not signed up yet ?',
    already_have_an_account: 'Already have an account ?',
    email: 'Email',
    user_name: 'User name',
    email_or_username: 'Email or Username',
    password: 'Password',
    confirm_password: 'Confirm password',
    new_password: 'New password',
    confirm_new_password: 'Confirm new password',
    created: 'Account successfully created !',
    forgot_password: 'Forgot password ?',
    reset_password: 'Reset password',
    change_password: 'Change password',
    reset_password_mail: 'An email has been sent with further instructions.',
    reset_password_success: 'Password successfully changed.',
    password_changed: 'Password changed.',
    delete_account: 'Delete account',
    delete_account_mail: 'An email has been sent with further instructions to delete your account.',
    delete_account_success: 'Account successfully deleted.',
    email_verification_success: 'Email sucessfully verified.',
    email_not_verified: 'Your email address have not yet be verified, please check your mail box to proceed for the verification. If you haven\'t received it, press the resend verification button :',
    email_verification_resend: 'Resend verification',
    location: 'Location',
    syncs: {
      activities_syncs: 'Activities syncs',
      connect: 'Connect',
      connected: 'Connected',
      disconnect: 'Disconnect',
      disconnection: 'Disconnection in progress.',
      garmin_tooltip: 'Allow activities completed on a Garmin device to be synced into your VO2Fast account.',
      garmin_training_tooltip: 'Garmin Training allow you to push planned workouts directly into your Garmin devices.',
      strava_limit: 'Please note that because of strava rate limits, we might not be able to load your last 90 days on your first synchronisation.',
      want_sync_now: 'Do you want to Synchronize your devices now ?',
      guide_sync_now: `Syncing your devices will allow us to import automatically your activities. This will allow us to calculate your power and heart rate profiles that will be used to determine your training targets.<br/>
      Syncing with Garmin will also allow us to push all your planned workouts on your devices.<br/>`,
      click_here_to_sync: 'To sync your devices, click here.',
      sync_later: 'If you want to sync later, you will be able to do it from the parameter page.',
      prefer_manual_import: 'You can also manually import your activities if you prefer:',
    },
    username_saved: 'Username saved.',
    accept_terms: 'By registering with VO2Fast, you agree to the <a href="https://vo2fast.com/terms">terms of use</a> and <a href="https://vo2fast.com/privacy_policy">privacy policy</a>.',
    prove_human: 'Prove that you are human',
    riddle: 'What is the result of ',
    bad_answer: 'Bad answer !',
    code: 'Code',
    enter_a_code: 'Enter a code',
    have_a_code: 'Have a promo code ?',
    code_validated: 'Code validated !',
    referrals: {
      referrals: 'Referrals',
      refer_friend: 'Refer a friend',
      offer_month: 'Offer a month, get a free month !',
      enter_code: 'If you have a referral code, enter it here.',
      description: `Do you have friends who may be interested in VO2Fast? Share this code below with them to use during registration and give them a free <b>{type}</b> month! <br/>
        For every person who subscribes after this free month, you will also receive a free month.`,
      have_free_month: 'You have a free trial month',
      have_free_month_description: `With your referral code, you got a free month of {accountType} VO2Fast.`,
    },
  },
  setup: {
    setup: 'Account setup',
    success: 'Setup success !',
    continue_to_plan: 'Continue to plan generation ?',
    want_to_continue_to_plan: 'Do you want to generate your first training plan now, or later ?',
  },
  feedback: {
    title: 'We are looking for feedback !',
    rate_app: 'How would you rate our app ?',
    rate_labels: ['Very poor', 'poor', 'average', 'good', 'excellent'],
  },
  general: {
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    see_more: 'See more',
    see_detail: 'See detail',
    welcome: 'Welcome',
    required_fields: '*Required fields',
    file: 'File',
    file_s: 'File(s)',
    send: 'Send',
    edit: 'Edit',
    modify: 'Modify',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    update: 'Update',
    back: 'Back',
    search: 'Search',
    add: 'Add',
    add_item: 'Add item',
    description: 'Description',
    title: 'Title',
    name: 'Name',
    tag: 'Tag',
    access: 'Access',
    public: 'Public',
    private: 'Private',
    select: 'Select',
    export: 'Export',
    close: 'Close',
    dropfiles: 'Drop your files here or click to upload',
    click_to_select: 'Click to select',
    validation: 'Validation',
    validate: 'Validate',
    next: 'Next',
    previous: 'Previous',
    suggestion: 'Suggestion',
    set_default: 'Set default',
    options: 'Options',
    continue: 'Continue',
    done: 'Done',
    stop: 'Stop',
    later: 'Later',
    other: 'Other',
    all: 'All',
    accept: 'Accept',
    copied_to_clipboard: 'Copied to clipboard',
    install: 'Install',
    install_app: 'Install app',
    still_beta: '/!\\ This feature is still in beta testing',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    type: 'type',
  },
  dates: {
    date: 'Date',
    time_day: 'Time',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    week_days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    months: ['January', 'February', 'March', 'April', 'May', 'June',
     'July', 'August', 'September', 'October', 'November', 'December'],
    week: 'Week',
    month: 'Month',
    year: 'Year',
    today: 'Today',
    tomorrow: 'Tomorrow',
    after_tomorrow: 'Aftermorrow',
  },
  infos: {
    infos: 'Infos',
    informations: 'Informations',
    personal_informations: 'Personal informations',
    saved: 'Infos saved.',
    birthday: 'Birthday',
    sex: 'Sex',
    male: 'Male',
    female: 'Female',
    age: 'Age',
  },
  metrics: {
    avg: 'avg',
    average: 'Average',
    min: 'min',
    max: 'max',
    distance: 'Distance',
    duration: 'Duration',
    ascent: 'Ascent',
    elevation: 'Elevation',
    height: 'Height',
    height_size: 'Height',
    calories: 'Calories',
    npower: 'NPower',
    load: 'Load',
    trimp: 'Trimp',
    speed: 'Speed',
    heart_rate: 'Heart Rate',
    hr: 'HR',
    power: 'Power',
    cadence: 'Cadence',
    intensity: 'Intensity',
    training_stress: 'Training stress',
    altitude: 'Altitude',
    temperature: 'Temperature',
    wbal: 'Wbal',
    weight: 'Weight',
    threshold: 'Threshold',
    threshold_power: 'Threshold power',
    threshold_heart_rate: 'Threshold heart rate',
    ftp: 'FTP',
    pmax: 'PMAX',
    anaerobic: 'Anaerobic',
    aet: 'Aet',
    anaerobic_capacity: 'Anaerobic capacity',
    map: 'MAP',
    max_aerobic: 'MAP',
    max_heart_rate: 'Max heart rate',
    max_power: 'Max power',
    resting_heart_rate: 'Resting heart rate',
    threshold_hr: 'Threshold HR',
    max_hr: 'Max HR',
    resting_hr: 'Resting HR',
    stamina: 'Stamina',
    meters: 'Meters',
    feets: 'Feets',
    kilometers: 'Kilometers',
    miles: 'Miles',
    kilograms: 'Kilograms',
    pounds: 'Pounds',
    celsius: 'Celsius',
    fahrenheit: 'Fahrenheit',
    kelvin: 'Kelvin',
    hrv: 'HRV',
    bpm: 'bpm',
    rpe: 'rpe',
    vo2: 'Vo2',
    vo2_est: 'Estimated Vo2',
    easy: 'Easy',
    endurance: 'Endurance',
    medium: 'Medium',
    hard: 'Hard',
    very_hard: 'Very Hard',
    set_model: 'Set model',
    help: {
      weight: 'Your current weight.',
      resting_heart_rate: 'You can use a timer to count the number of beats while sitting still for 30 seconds and double it to get your resting heart rate.',
    },
  },
  zone: {
    zone: 'Zone',
    zones: 'Zones',
    my_zones: 'My zones',
    power_zones: 'Power zones',
    heart_rate_zones: 'Heart rate zones',
    recovery: 'Recovery',
    endurance: 'Endurance',
    tempo: 'Tempo',
    threshold: 'Threshold',
    vo2max: 'Vo2Max',
    anaerobic: 'Anaerobic',
    sprint: 'Sprint',
  },
  home: {
    headline: 'Get Faster !',
    subline: 'With a training plan that fit you, based on automated data analysis of your activities, your schedule and your goals.',
    start_training: 'Start Training !',
    learn_more: 'Learn more',
    plan_card_title: 'Get a plan for your whole season.',
    plan_card_content: 'Enter your goals for the season and VO2Fast will automatically generate the training blocks you need to get ready for your goals.',
    schedule_card_title: 'A plan that fit to your schedule',
    schedule_card_content: 'Select the amount of time you can train for each day and the average amount of time you want to train for each week and the plan will adapt itself.',
    data_card_title: 'Automated data analysis of your activities',
    data_card_content: 'Your power (and heart rate) profile will be automatically updated after each of your activities, allowing you to track your progression and have workouts always correctly calibrated to your levels.',
    how_does_it_work: 'How does it work ?',
  },
  add_item: {
    add_to_calendar: 'What do you want to add to your calendar ?',
  },
  dashboard: {
    this_week: 'This Week',
    statistics: 'Statistics',
    week_summary: 'Summary of the week',
    total: 'Total',
    power_trends: 'Power trends'
  },
  news: {
    news: 'News',
  },
  performance: {
    performance: 'Performance',
    performances: 'Performances',
    progression: 'Progression',
    fitness: 'Fitness',
    fatigue: 'Fatigue',
    form: 'Form',
    training_load: 'Training load',
    power_curve: 'Power Curve',
    heart_rate_curve: 'Heart rate Curve',
    records: 'Records',
  },
  activity: {
    activity: 'Activity',
    activities: 'Activities',
    summary: 'Summary',
    statistics: 'Statistics',
    course: 'Course',
    charts: 'Charts',
    records: 'Records',
    data: 'Data',
    planned: 'Planned',
    done: 'Done',
    power_curve: 'power curve',
    heart_rate_curve: 'Heart rate Curve',
    selection: 'Selection',
    upload_activity: 'Upload Activity',
    manually_upload: 'Manually upload activities',
    upload_an_activity: 'Upload an activity',
    wait_for_activity: 'Wait for a Garmin or Strava activity',
    no_activity_file: `I don't have an activity file.`,
    new_activity: 'New Activity',
    add_activity: 'Add activity',
    edit_activity: 'Edit activity',
    activity_name: 'Activity name',
    rpe: 'RPE (1-10)',
    sport: 'Sport',
    cycling: 'Cycling',
    running: 'Running',
    strength_training: 'Strength training',
    power_hr_ratio: 'Power/Hr ratio',
    aerobic_decoupling: 'Aerobic decoupling',
    view_strava: 'View on Strava',
    ignore_power: 'Ignore power data',
    ignore_heart_rate: 'Ignore heart rate data',
    ignore_power_details: 'If this activity contains faulty power data, you can check this to make the app ignore this activity when calculating power curves and models.',
    ignore_heart_rate_details: 'If this activity contains faulty heart rate data, you can check this to make the app ignore this activity when calculating heart rate curves and models.',
    rate_activity: 'Rate activity',
    rate_intensity: 'Rate intensity',
  },
  measure: {
    measure: 'Measure',
    new_measure: 'New measure',
    edit_measure: 'Edit measure',
    my_measures: 'My measures',
  },
  model: {
    new_model: 'New Model',
    update_measure: 'Create new measure from model',
    no_current_model: 'No current model.',
    updated: 'Your model has been updated',
    current_estimated_model: 'Current estimated model: ',
    set_model_warning: '',
  },
  curves: {
    percent_best: '% of best',
    power_last_days: ' days of power data.',
    heart_rate_last_days: ' days of heart rate data.',
    power_model: 'Power Model (estimated)',
  },
  tools: {
    tools: 'Tools',
    direct_link: 'Direct link to this tool',
    ftp_estimator: 'FTP estimator',
    estimated_ftp: 'Estimated FTP',
    estimated_anaerobic_capacity: 'Estimated anaerobic capacity',
    best_short_effort: 'Your best short effort (2-10min)',
    best_long_effort: 'Your best long effort (10-60min)',
  },
  workout: {
    workout: 'Workout',
    training: 'Training',
    library: 'Workout Library',
    new: 'New Workout',
    saved: 'Workout saved.',
    create_workout: 'Create workout',
    edit_workout: 'Edit workout',
    clone_workout: 'Clone workout',
    delete_workout: 'Delete workout',
    plan_workout: 'Plan workout',
    your_workouts: 'Your workouts',
    vo2fast_workouts: 'VO2Fast workouts',
    next_workouts: 'Next workouts',
    step: 'Step',
    steps: 'Steps',
    repeatitions: 'Repeatitions',
    add_step: 'Add step',
    add_repeat: 'Add repeatition',
    duplicate_step: 'Duplicate step',
    other: 'Other',
    test: 'Test',
    recovery: 'Recovery',
    endurance: 'Endurance',
    tempo: 'Tempo',
    threshold: 'Threshold',
    vo2max: 'Vo2Max',
    anaerobic: 'Anaerobic',
    sprint: 'Sprint',
    estimations: 'Estimations',
    target: 'Target',
    cadence_target: 'Cadence target',
    bind_with: 'Bind with ',
    unbind: 'Unbind',
    mark_as_completed: 'Mark workout as completed',
    planned: 'Planned',
    completed: 'Completed',
    interval: 'Interval',
    warmup: 'Warmup',
    cooldown: 'Cooldown',
    text_description: 'Text description of steps',
    end_lap_button: 'End step on LAP button.',
    press_lap_button: 'Press the LAP button once ready to go to the next step',
    today_workout: 'Today\'s workout',
    tomorrows_workout: 'Tomorrow\'s workout',
    workout_in_n_days: 'Workout in {n} days',
    upcoming_workouts: 'Upcoming workouts',
    rest: 'Rest',
    change_to_power: 'Change to power',
    change_to_hr: 'Change to HR',
    change_to_rpe: 'Change to RPE',
    export_to_zwift: 'Export for Zwift',
    zwift_ftp_title: 'Don\'t forget to update your Zwift FTP',
    zwift_ftp_message: 'Your current ftp is {ftp}W. Before starting this workout, don\'t forget to update your Zwift FTP to this value to get the correct power targets.',
    export_help: 'To get your workouts on Zwift, export and download the Zwift workout file and put it in your Zwift workouts folder. For more informations <a href="https://www.zwift.com/news/11792-importing-and-sharing-custom-zwift-workouts">see here.</a>',
    validate_workout: 'Validate workout',
    did_you_do_workout: 'Did you do this workout?',
    did_another_ride: 'I did another workout.',
    not_done_yet: 'I haven\'t done it yet.',
    not_training_today: `I'm not training today`,
    add_activity: 'Add workout activity',
    validate_without_file: 'Validate my workout without activity file.',
    add_ride: 'Details of the workout you did',
    text_description_help: 'Guide to create workouts from text',
    text_description_help_content: `
    <p>Using the text description to create workout will be the fastest way to do it once you know how to do it.</p>
    <p>First you have to describe your steps. Each step have to be separated by a comma. A step can be defined like this: <br/>
    <b>time@target</b><br/>
    Where time will be the time spent in the step and can be described like this:  h:mm:ss (example 1:23:00 for 1h23).<br/>
    You can omit hours (or minutes) if it's 0.  For example 5:00 will be 5min and 37 will be 37s.<p/>
    <p>The target will be either a power target, heart rate target or RPE target. (And you can add a cadence target too).<br/>
    If you want a power target, you can target either a % of ftp, a % of MAP (Max aerobic power), a % of max power (sprint power), or a % of anaerobic capacity. <br/>
    <b>Examples:</b><br/>
    <b>5:00@95%ftp</b> will target 95% of your ftp for 5 minutes.<br/>
    <b>5:00@95%map</b> will target 95% of your max aerobic power for 5 minutes.<br/>
    <b>10@100%max</b> will target 100% of your max power for 10 seconds.<br/>
    <b>5:00@50%wbal</b> will target a power target that will empty your anaerobic capacity by 50% over 5 minutes.</p>
    For a heart rate target, you can either target a % of threshold hr or a % of max hr: <br/>
    <b>5:00@100%hrt</b> will target your threshold heart rate for 5min.<br/>
    <b>5:00@95%hrmax</b> will target 95% of your max heart rate for 5min.</p>
    <p>If you wish to target RPE, you can target it with number(1-10)+rpe. Eg: <b>5:00@7rpe</b>.<br/>
    You can also add a cadence target with target rpm. Example <b>5:00@70%ftp80rpm</b></p>
    <p>You can also write step repeatition with this form: nb*(steps).<br/>
    <b>Examples:</b><br/>
    <b>4*(10:00@100%ftp, 5:00@50%ftp)</b> will create 4 repeatitions of 10min ftp intervals with 5min rest.<br/>
    Your steps repeat can also contains another steps repeat. For example:<br/>
    <b>4*(10*(30@100%map, 30@50%ftp), 5:00@50%ftp)</b> will create 4 intervals of 30/30 with 5min rest between.</p>
    <h5 class="title is-5">Options</h5>
    <p><b>Lap button</b><br/>
    On each step, you can add the option to require the lap button (for Garmin) to be pressed to go on the next step.
    This is useful to let the user go on a suitable location to do his interval instead of starting it automatically.<br/>
    To add this, you just have to add lap at the end of the step. Example: 5:00@50%ftp lap<br/>
    By default, the lap button will be required for each recovery step between intervals longer than 3 minutes. If you don't want this behavior, you can add !lap at the end of your step.</p>`,
  },
  settings: {
    profile: 'My profile',
    display_preferences: 'Display preferences',
    save: 'Save',
    language: 'Language',
    settings_saved: 'Change in settings saved.',
  },
  equipment:{
    equipment: 'Equipment',
    heart_rate_monitor: 'Heart rate monitor',
    power_meter: 'Power meter',
    home_trainer_power: 'Home trainer with power',
    help: 'Select the equipments that you can use for your training.',
  },
  objective: {
    objectives: 'Objectives',
    objective: 'Objective',
    my_objectives: 'My objectives',
    add_objective: 'Add objective',
    new_objective: 'New objective',
    edit_objective: 'Edit objective',
    remove_objective: 'Remove objective',
    skills: 'Skills',
    add_skill: 'Add skill',
    threshold: 'Threshold',
    sprint: 'Sprint',
    anaerobic: 'Anaerobic',
    max_aerobic: 'MAP',
    endurance: 'Endurance',
    flat: 'Flat',
    hilly: 'Hilly',
    climbing: 'Climbing',
    timetrial: 'Time Trial',
    stage: 'Stage Race',
    profile_type: 'Profile type',
    sprinter: 'Sprinter',
    puncher: 'Puncher',
    climber: 'Climber',
    rouleur: 'Rouleur',
    grandfondo: 'Grand Fondo/Endurance',
    estimated_duration: 'Estimated event duration',
    priority: 'Priority',
    only_show: 'Only show: ',
  },
  training_ride: {
    training_ride: 'Training ride',
    training_rides: 'Training rides',
    plan_workout_or_training_ride: 'Do you want to plan a workout or a training ride ?',
    plan_workout_or_training_ride_desc: 'Plan a workout if you want to follow a specific workout with instructions. Plan a training ride if you just want to loosely plan a ride (a group ride for example). The training plan will automatically update if you plan a training ride.',
    plan_training_ride: 'Plan a training ride',
    new_training_ride: 'New training ride',
    old_training_ride: 'Add training ride',
    add_training_ride: 'Add training ride',
    edit_training_ride: 'Edit training ride',
    remove_training_ride: 'Remove_training_ride',
    estimated_duration: 'Estimated duration',
    estimated_distance: 'Estimated distance',
    estimated_elevation: 'Estimated elevation',
    planned_intensity: 'Planned intensity',
    intensity_done: 'Intensity done',
    mark_as_completed: 'Mark training ride as done.',
    unbind: 'Unbind training ride',
    did_you_do_training_ride: 'Did you do this training ride ?',
  },
  subscription: {
    subscription: 'Subscription',
    my_subscription: 'My subscription',
    subscribe: 'Subscribe',
    subscribe_now: 'Subscribe now',
    what_you_get: 'With this subscription, you will get access to a personalized training plan that matches your objectives, you available training time for each week and your training level. You will also have access to the VO2Fast workout library.',
    monthly: 'Monthly',
    annually: 'Annually',
    billed_annually: 'Billed annually',
    billed_monthly: 'Billed monthly',
    per_year: 'per year',
    per_month: 'per month',
    thanks: 'Thank you for your subscription',
    being_treated: '<p>Your subscription is being treated. Please wait a few seconds and reload the page to get access to all the app features. If after a few minutes you still don\'t have access, contact us at: <a href="mailto:contact@vo2fast.com">contact@vo2fast.com</a></p>',
    payment_cancelled: 'Your payment have been cancelled.',
    manage_billing: 'Manage your subscription',
    subscribe_to_standard_access: 'Subscribe to at least VO2Fast STANDARD plan to get access to this.',
    subscribe_to_premium_access: 'Subscribe to VO2Fast PREMIUM plan to get access to this.',
    subscribe_to_get_access: 'Subscribe to get access to the training plan',
    subscribe_to_get_vo2_workouts: 'Subscribe to get access to the VO2Fast workout library',
    require_premium_subscription: 'This is available with the premium VO2Fast plan. <a href="/profile">Click here to change plan.</a>',
    days_free: 'If you still have time in the free trial period, by subscribing now you will be billed at the end of your trial period.',
    currently_special_access: 'You currently have a special access with account type: ',
    currently_not_subscribed: 'You are currently not subscribed. To get access to more features or to unlimited training plan, you can subscribe below.',
    currently_free_trial: 'You are currently on a free trial period with a {type} account until {date}. If you like our training plans, you can subscribe below.',
    currently_subscribed: 'You are currently subscribed with a {type} subscription until {date}.',
    select_plan: 'Select a subscription',
    select_another_plan: 'Select another subscription plan',
    select_plan_text: `If you want to subscribe, please select a subscription plan. You can also continue with the free version for now.`,
  },
  plan: {
    my_plan: 'My plan',
    plan_data: 'Plan data',
    no_current_plan: 'You currently don\'t have a training plan.',
    generate_training_plan: 'Generate Training Plan',
    update: 'Update training plan',
    want_update: 'Update your entire training plan ?',
    update_details: 'You made a change with one of your A objective, do you want to update your entire training plan to reflect that change ?',
    regenerate_all_workouts: 'Regenerate all workouts',
    regenerate_my_plan: 'Regenerate my plan',
    want_regenerate: 'Do you want to regenerate your plan ?',
    regenerate_details: `Doing this will regenerate you a whole new plan that will start from the beginning and remove your current plan and progress.
    If you want to keep your plan but change some datas (availabilities or training level), you can do so from your profile page.
    You can also add/edit/remove your A objective from your calendar or your profile page, a prompt will ask if you want to update your current plan to take them into account.`,
    update_my_plan: 'Update my plan',
    delete_my_plan: 'Delete my plan',
    want_delete: 'Are you sure you want to delete your plan ?',
    delete_details: 'If you press yes your whole plan (including planned training blocks and workouts) will be deleted.',
    training_time: 'Training time',
    this_week_training_time: 'This week training time',
    estimated_annual_time: 'Estimated annual training time',
    week_training_time: 'Average available week training time',
    max_week_training_time: 'Max available week training time',
    day_training_time: 'Available training time for each day',
    practice_years: 'Years of practice',
    start_date: 'Plan starting date',
    success: 'The plan has been generated.',
    time_updated: 'Your training time has been updated.',
    include_preparation_week: 'Include a first week of preparation with light workouts.',
    include_testing_week: 'Include a full week of testing to calibrate your workouts.',
    regenerate_week: 'Regenerate week',
    week_time: 'Week time',
    change_week_time: 'Change your available time',
    change_global_week_time: 'Change your global available time.',
    reload_default_week_time: 'Reload default week times',
    generating_plan: 'Currently generating your training plan. This can take up to a few minutes.',
    updating_plan: 'Currently updating your training plan.',
    selected_week: 'Selected week',
    indoor: 'Home trainer',
    outdoor: 'Outdoor riding',
    delete_workout_add_activity: 'Delete the workout and add my activity',
    delete_workout_import_activity: 'Delete the workout and upload my activity',
    delete_workout_wait_activity: 'Delete the workout and wait for the Garmin/Strava activity',
    free_limit_days: 'With your free account, you are currently limited to 3 days of training a week and 1 day on home trainer. If you want the unlimited plan, you can <a href="/profile">subscribe here.</a>',
    free_limit_objectives: 'With your free account, you are currently limited to one A objective for your training plan. If you want the unlimited plan, you can <a href="/profile">subscribe here.</a>',
    blocks: {
      block: 'Block',
      blocks: 'Blocks',
      type_block: '{block} block',
      transition: 'transition',
      testing: 'testing',
      preparation: 'preparation',
      base: 'base',
      build: 'build',
      recovery: 'recovery',
      taper: 'taper',
      race: 'race',
    },
    training_level: 'Your experience level with interval training',
    levels: [
      'Beginner',
      'Novice',
      'Intermediate',
      'Advanced',
      'Expert',
      'Pro',
    ],
    help: {
      syncs: 'Syncs your activities (or upload them manually). This will allow VO2Fast to calculate your power profile (if you have power) and your heart rate profile (if you have a heart rate monitor). Syncing to Garmin will also allow VO2Fast to push workout on your Garmin.',
      start_date: 'Your plan will start on the day you enter here.',
      practice_years: 'The number of years you have been practicing an endurance sport.',
      week_training_time: 'Select the average time per week you want to train here. Be aware that the trainings weeks hours can be higher or lower.',
      estimated_annual_time: 'This is the estimated time you will train during the year based on the average time per week you gave.',
      max_week_training_time: 'Select the max time per week you have available. This put a hard cap on the training time for each week.',
      day_training_time: 'Select your max available training time for each day of the week. Let it at 0 for days where you can\'t train.',
      objectives: 'Plan your objectives here. You need to plan at least all your A objectives for the season to generate all the training blocks. You will be able to add every other objective in the calendar. If you don\'t have an event you train for, your objective can simply be a date where you want to be fit.',
      include_preparation_week: 'Check this if you want to include a preparation week as your first week. This is recommended if you haven\'t seriously started to train for your season.',
      include_testing_week: '(Recommended) Check this if you want to include a testing week. This will make you do the baselines test to establish your power profile (if you have a power meter or a home trainer with power) and your heart rate profile (if you have a heart rate monitor). This is recommended to properly set your training zones.',
      training_level: `Estimate your training level with intervals training: <br/>
                        <b>Total beginner:</b> You started cycling very recently. <br/>
                        <b>Novice:</b> You have (almost) no experience with structured training. <br/>
                        <b>Intermediate:</b> You have some structured training experience. <br/>
                        <b>Advanced:</b> You have at least 2 years of structured training experience. <br/>
                        <b>Expert:</b> You have a few years of structured training experience. <br/> `,
      training_level_base: `Estimate your training level with intervals training (with the free version, you only have access to beginner and novice training plans): <br/>
                            <b>Total beginner:</b> You started cycling very recently. <br/>
                            <b>Novice:</b> You have (almost) no experience with structured training. <br/>`,

    },
  },
  onboarding: {
    skip: 'Skip',
    previous: 'Previous',
    next: 'Next',
    finish: 'Finish',
    calendar0: `Welcome on your calendar. You've just generated a plan, we'll take you on a little tour of what you can do to update your plan.`,
    calendar1: `First of all, to add an item or modify your plan, you can do it from this + button. On PC you can also do it directly in the calendar boxes.`,
    calendar2: `For each week you have the possibility to change your availabilities. The plan of the week will be automatically updated with each change. If you want to change your global availabilities for every week, you can do it from your profile, the whole plan will then be regenerated.`,
    calendar3: `You can also add objectives (or delete them from the calendar). You can also see the list of your objectives from your profile. The plan will be recalculated to take them into account each time they change.`,
    calendar4: `In addition to the objectives, you will also be able to plan training rides during the week (if you have a group ride planned, for example). The plan will take these into account, just make sure you plan the intensity of your outing correctly.`,
  },
  coaching: {
    coach: 'Coach',
    coaching: 'Coaching',
    plan_data: 'Plan data',
    quit_user: 'Quit User',
    athlete: 'Athlete',
    athletes: 'Athletes',
    invite: 'Invite athletes',
    to_invite: 'To invite your athletes to give you access to their training data, send them this link: ',
    want_to_coach: 'send to you this link because he want to coach you. If you accept he will be able to access your training data and plan workouts.',
    login_to_accept: 'You must first login or sign up to accept.',
    is_now_your_coach: ' is now your coach.',
    already_have_coach: 'You already have a coach, accepting this new coach will overide your current coach.',
    revoke_athlete: 'Revoke athlete',
    is_your_current_coach: ' is your current coach.',
    revoke_coach: 'Revoke coach',
    coach_revoked: 'Coach revoked.',
  },
  meta: {
    description: 'Get faster with VO2Fast, dynamic training plans and analysis tools to increase your cycling performances.',
  },
  offline: {
    offline: 'You are offline. The app need an internet connection to work.',
    reload: 'Reload',
  },
  cookies: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    got_it: 'Got it!'
  },
  errors: {
    error: 'Error',
    page_load: {
      loading_error: 'A loading error occured.',
      loading_error_description: 'An error occured while loading a component. Reloading may help.',
      reload_page: 'Reload',
    },
    account: {
      enter_email: 'Please enter an email.',
      enter_email_or_username: 'Please enter either your email or your username.',
      invalid_email: 'Invalid email.',
      invalid_password: 'Invalid password.',
      invalid_new_password: 'Invalid new password.',
      enter_name: 'Please enter a user name.',
      enter_password: 'Please enter a password.',
      username_not_email: 'Username should not be an email.',
      different_password: 'The two password are different.',
      incomplete_data: 'Incomplete data.',
      bad_login: 'Bad login information.',
      invalid_token: 'Invalid token.',
      token_expired: 'Link expired.',
      unknown_user_id: 'Unknown user id',
      already_deleted: 'Account already deleted.',
      invalid_code: 'Invalid code',
      already_used_code: 'You already used a code.',
      code_already_used: 'This code have already been used.',
      invalid_referral: 'Invalid referral code.',
    },
    general: {
      enter_name: 'Please enter a name.',
      invalid_date: 'Invalid date.',
    },
    activity: {
      not_exist: 'Activity doesn\'t exist !',
      invalid: 'Invalid activity',
      duplicate: 'Activity already exist.',
    },
    measure: {
      invalid: 'Invalid measure.',
    },
    workout: {
      invalid: 'Invalid workout.',
    },
    objective: {
      invalid: 'Invalid objective.',
      limited_base: 'With the free version, you are limited to a single main objective (A).',
    },
    training_ride: {
      invalid: 'Invalid training ride.',
      pick_intensity: 'You must pick a planned intensity for your ride.',
    },
    plan: {
      week_time_0: 'Please enter the average (and max) time you want to train each week',
      days_time_0: 'Please enter the available time you can train for each day',
      training_days_less_2: 'You must have at least 2 training days.',
      days_time_inferior_max_week_time: 'Your max available time per week can\'t be bigger than the sum of your time available for each day of the week.',
      no_A_objective: 'There must be at least one objective with an A priority.',
      no_training_level: 'You must set your training level.',
      free_limit_days: 'With your free account, you are currently limited to 3 days of training a week and 1 day on home trainer. If you want the unlimited plan, you can subscribe from your profile page.',
    },
    invalid_data: 'Invalid data.',
  },
  notfound: '404 Not Found !',
  notifications: englishNotifications,
  doc: englishDoc,
  privacy_policy: englishPrivacyPolicy,
  terms: englishTerms,
  legal: englishLegal,
  workouts_lib: englishWorkouts,
  mails: englishMails,
  landing: englishLanding,
  faq: englishFAQ,
  whatsapp_link: 'https://chat.whatsapp.com/Clu2LL0NbGPIShtSR2vpz1',
  roadmap_link: '',
};
