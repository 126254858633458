<template>
  <div class="navigation is-family-primary has-text-weight-bold" v-if="isVisible">
    <div v-if="!isLoggedIn">
      <b-navbar fixed-top spaced>
        <template slot="brand">
          <b-navbar-item tag="router-link" to="/">
            <img src="@/../public/icon.png"/>
            <h2 class="is-title is-2 is-italic has-text-weight-bold">
              <span class="has-text-primary">VO2</span><span class="has-text-secondary">Fast</span>
            </h2>
          </b-navbar-item>
        </template>

        <template slot="end">
          <!--<b-navbar-item tag="router-link" class="has-text-accent-darker" to="/features">{{ $t('nav.features') }}</b-navbar-item>
          <b-navbar-item tag="router-link" class="has-text-accent-darker" to="/team">{{ $t('nav.team') }}</b-navbar-item>
          <b-navbar-item tag="router-link" class="has-text-accent-darker" to="/help">{{ $t('nav.faq') }}</b-navbar-item>
          <b-menu-item tag="a" href="https://forum.vo2fast.com" :label="$t('nav.forum')"/>-->

          <b-navbar-item tag="a" class="has-text-accent-darker" href="https://blog.vo2fast.com">Blog</b-navbar-item>
          <b-navbar-item tag="router-link" class="has-text-accent-darker" to="/signup">{{ $t('nav.signup') }}</b-navbar-item>
          <b-navbar-item tag="router-link" class="button is-primary" to="/login">{{ $t('nav.login') }}</b-navbar-item>
          <b-navbar-item><LanguageSwitch inputType="selectFlag"/></b-navbar-item>
          <b-navbar-item tag="router-link" class="has-text-accent-darker" to="/business_signup">{{ $t('nav.business_space') }}</b-navbar-item>
        </template>
      </b-navbar>
    </div>

    <div v-if="isLoggedIn">
      <div v-if="$root.isDesktop()">
        <b-sidebar class="sidebar" position="static" type="is-white" fullheight open>
          <div class="p-5 h-100vh is-flex is-flex-direction-column is-justify-content-space-between has-text-left">
            <div>
              <a href="/" class="block mt-2">
                <img width="160" src="@/../public/logo.png" alt="VO2Fast"/>
              </a>
              <b-menu class="mt-5">
                <b-menu-list>
                  <b-menu-item icon="home" icon-size="is-medium" :label="$t('nav.dashboard')"
                    tag="router-link" to="/dashboard"/>
                  <b-menu-item icon="calendar-blank" :label="$t('nav.calendar')"
                    tag="router-link" to="/calendar"/>
                  <b-menu-item icon="flag-checkered" :label="$t('plan.generate_training_plan')"
                    tag="router-link" to="/generate_training_plan"/>
                  <b-menu-item icon="chart-timeline-variant" :label="$t('nav.performances')"
                    tag="router-link" to="/performances"/>
                  <b-menu-item icon="account-outline" :label="$t('account.profile')"
                    tag="router-link" to="/profile"/>


                  <b-menu-item v-if="$store.getters.hasPremiumAccess">
                    <template #label="props">
                      {{ $t('tools.tools') }} <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
                    </template>

                    <b-menu-item tag="router-link" to="/workout_creator" :label="$t('nav.workout_creator')"/>
                    <b-menu-item tag="router-link" to="/workout_library" :label="$t('workout.library')"/>
                    <!--<b-menu-item href="#" v-on:click="$eventBus.$emit('show-modal-tools', { tool: 'ftp_estimator' })" :label="$t('tools.ftp_estimator')"/>-->
                    <b-menu-item v-if="user && $store.getters.hasAdminAccess" tag="router-link" to="/block_editor" :label="'Block Editor'"/>
                    <b-menu-item v-if="user && $store.getters.hasAdminAccess" tag="router-link" to="/mail_sender" :label="'Mail Sender'"/>
                    <b-menu-item v-if="user && $store.getters.hasAdminAccess" tag="router-link" to="/stats" :label="'Stats'"/>
                    <b-menu-item v-if="user && $store.getters.hasAdminAccess" tag="router-link" to="/search" :label="$t('general.search')"/>
                    <b-menu-item v-if="user && $store.getters.hasAdminAccess" tag="router-link" to="/codes_manager" :label="'Codes'"/>
                  </b-menu-item>

                  <b-menu-item v-if="user && $store.getters.hasCoachAccess" tag="router-link" to="/coaching_view"
                    :label="$t('coaching.coaching')"/>
                  <b-menu-item v-if="user && $store.getters.hasCoachAccess && $store.getters.loadedUser"
                    tag="router-link" to="/training_plan_data" :label="$t('coaching.plan_data')"/>
                  <b-menu-item v-if="user && $store.getters.loadedUser"
                  @click="$store.dispatch('setLoadedUser', null)" :label="$t('coaching.quit_user')"/>


                  <b-menu-item tag="a" href="https://forum.vo2fast.com" :label="$t('nav.forum')"/>
                  <b-menu-item tag="a" href="https://blog.vo2fast.com" :label="$t('nav.blog')"/>
                  <b-menu-item tag="router-link" to="/news" :label="$t('news.news')"/>


                  <!--<b-menu-item>
                    <template #label="props">
                      {{ $t('nav.community') }} <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
                    </template>
                    <b-menu-item tag="a" href="https://forum.vo2fast.com" :label="$t('nav.forum')"/>
                    <b-menu-item tag="a" href="https://blog.vo2fast.com" :label="$t('nav.blog')"/>
                  </b-menu-item>-->

                  <!--<b-menu-item tag="router-link" class="button is-primary" to="/referrals" :label="$t('account.referrals.refer_friend')"/>-->
                  <router-link class="button is-primary" to="/referrals"><span class="has-text-white">{{ $t('account.referrals.refer_friend') }}</span></router-link>


                </b-menu-list>
              </b-menu>
            </div>

            <b-menu>
              <b-menu-list>
                <b-menu-item icon="help-circle" :label="$t('nav.help')" tag="router-link" to="/help"/>
                <b-menu-item tag="a" @click="$eventBus.$emit('show-modal-upload-activity')"
                  :label="$t('activity.upload_activity')" icon="file-upload"/>
                <b-menu-item icon="cog" :label="$t('nav.settings')"
                  tag="router-link" to="/settings"/>
                <b-menu-item icon="power" :label="$t('nav.logout')" @click.native="logout()"/>
              </b-menu-list>
            </b-menu>
          </div>
        </b-sidebar>
      </div>

      <div v-if="$root.isTabletOnly()">
        <b-navbar fixed-top>
          <template slot="brand">
            <b-navbar-item tag="router-link" to="/dashboard">
              <img src="@/../public/icon.png"/>
              <h2 class="is-title is-2 is-italic has-text-weight-bold">
                <span class="has-text-primary">VO2</span><span class="has-text-secondary">Fast</span>
              </h2>
            </b-navbar-item>
          </template>

          <template slot="start">
            <b-navbar-item v-if="user && $store.getters.hasCoachAccess" tag="router-link" to="/coaching_view">{{ $t('coaching.coaching') }}</b-navbar-item>
            <b-navbar-item v-if="user && $store.getters.hasCoachAccess && $store.getters.loadedUser" tag="router-link" to="/training_plan_data">
              {{ $t('coaching.plan_data') }}
            </b-navbar-item>
            <b-navbar-item v-if="user && $store.getters.loadedUser" @click="$store.dispatch('setLoadedUser', null)">{{ $t('coaching.quit_user') }}</b-navbar-item>
            <b-navbar-item tag="router-link" to="/referrals">{{ $t('account.referrals.refer_friend') }}</b-navbar-item>

          </template>
          <template slot="end">
            <b-navbar-item tag="a" @click="$eventBus.$emit('show-modal-upload-activity')">
              <b-tooltip :label="$t('activity.upload_activity')" position="is-bottom"><b-icon icon="file-upload"/></b-tooltip>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/help">
              <b-tooltip :label="$t('nav.help')" position="is-bottom"><b-icon icon="help-circle"/></b-tooltip>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/contact">
              <b-tooltip :label="$t('nav.contact')" position="is-bottom"><b-icon icon="comment-question"/></b-tooltip>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/settings">
              <b-tooltip :label="$t('nav.settings')" position="is-bottom"><b-icon icon="cog"/></b-tooltip>
            </b-navbar-item>
            <b-navbar-item @click.native="logout()">{{ $t('nav.logout') }}</b-navbar-item>
          </template>
        </b-navbar>


        <b-navbar fixed-bottom :mobile-burger="false" v-if="isLoggedIn" centered>
          <template slot="start">
            <b-navbar-item tag="router-link" to="/dashboard" class="is-expanded is-block has-text-centered">
              <b-icon icon="home"/>
              <p class="is-size-7">{{ $t('nav.dashboard') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/calendar" class="is-expanded is-block has-text-centered">
              <b-icon icon="calendar-blank"/>
              <p class="is-size-7">{{ $t('nav.calendar') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/generate_training_plan" class="is-expanded is-block has-text-centered">
              <b-icon icon="flag-checkered"/>
              <p class="is-size-7">{{ $t('plan.generate_training_plan') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/performances" class="is-expanded is-block has-text-centered">
              <b-icon icon="chart-timeline-variant"/>
              <p class="is-size-7">{{ $t('nav.performances') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/profile" class="is-expanded is-block has-text-centered">
              <b-icon icon="account-outline"/>
              <p class="is-size-7">{{ $t('account.profile') }}</p>
            </b-navbar-item>
          </template>
        </b-navbar>
      </div>






      <div v-if="$root.isMobile()">
        <b-navbar fixed-top>
          <template slot="brand">
            <b-navbar-item tag="router-link" to="/dashboard">
              <img src="@/../public/icon.png"/>
              <h2 class="is-title is-2 is-italic has-text-weight-bold">
                <span class="has-text-primary">VO2</span><span class="has-text-secondary">Fast</span>
              </h2>
            </b-navbar-item>
          </template>

          <template slot="start">
            <b-navbar-item v-if="isLoggedIn && user" tag="router-link" to="/generate_training_plan">
              <b>{{ $t('plan.generate_training_plan') }}</b>
            </b-navbar-item>

            <b-navbar-item v-if="user && $store.getters.hasCoachAccess" tag="router-link" to="/coaching_view">{{ $t('coaching.coaching') }}</b-navbar-item>
            <b-navbar-item v-if="user && $store.getters.hasCoachAccess && $store.getters.loadedUser" tag="router-link" to="/training_plan_data">
              {{ $t('coaching.plan_data') }}
            </b-navbar-item>
            <b-navbar-item v-if="user && $store.getters.loadedUser" @click="$store.dispatch('setLoadedUser', null)">{{ $t('coaching.quit_user') }}</b-navbar-item>



            <b-navbar-item tag="a" @click="$eventBus.$emit('show-modal-upload-activity')">
              <p><b-icon icon="file-upload"/>{{ $t('activity.upload_activity') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/settings">
              <p><b-icon icon="cog"/>{{ $t('nav.settings') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/help">
              <p><b-icon icon="help-circle"/>{{ $t('nav.help') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/contact">
              <p><b-icon icon="comment-question"/>{{ $t('nav.contact') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/referrals">
              {{ $t('account.referrals.refer_friend') }}
            </b-navbar-item>
            <router-link class="button is-primary" to="/referrals"><span class="has-text-white">{{ $t('account.referrals.refer_friend') }}</span></router-link>

            <b-navbar-item @click.native="logout()">{{ $t('nav.logout') }}</b-navbar-item>
          </template>
        </b-navbar>


        <b-navbar fixed-bottom :mobile-burger="false">
          <template slot="brand">
            <b-navbar-item tag="router-link" to="/dashboard" class="is-expanded is-block has-text-centered">
              <b-icon icon="home"/>
              <p class="is-size-7">{{ $t('nav.dashboard') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/calendar" class="is-expanded is-block has-text-centered">
              <b-icon icon="calendar-blank"/>
              <p class="is-size-7">{{ $t('nav.calendar') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/performances" class="is-expanded is-block has-text-centered">
              <b-icon icon="chart-timeline-variant"/>
              <p class="is-size-7">{{ $t('nav.performances') }}</p>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/profile" class="is-expanded is-block has-text-centered">
              <b-icon icon="account-outline"/>
              <p class="is-size-7">{{ $t('account.profile') }}</p>
            </b-navbar-item>
          </template>
        </b-navbar>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from '@/components/LanguageSwitch.vue';

const ROUTES_HIDE = ['/offline', '/login', '/signup', '/business_space', '/business_signup'];

export default{
  name: 'Navigation',
  computed : {
    isLoggedIn(){ return this.$store.getters.isLoggedIn; },
    user(){ return this.$store.getters.user; },
    isVisible(){ return !ROUTES_HIDE.includes(this.$route.path) && !this.$store.getters.isBusinessAccount; },
    hasTopNavbar(){ return this.isVisible && (!this.isLoggedIn || !this.$root.isDesktop()); },
  },
  methods: {
    logout(route='/') {
      if(!this.isLoggedIn)
        return;
      this.$store.dispatch('logout');
      if(route != this.$router.currentRoute.path)
        this.$router.push(route);
    },
  },

  watch: {
    hasTopNavbar(){ //Watch topnavbar to add or remove has-navbar-fixed-top class (class that add a padding to body for topnavbar)
      if(this.hasTopNavbar){
        document.body.classList.add('has-navbar-fixed-top');
      }else{
        document.body.classList.remove('has-navbar-fixed-top');
      }
    },
  },
  components: {
    LanguageSwitch,
  },
};
</script>

<style lang="scss">
a.router-link-exact-active, a.router-link-exact-active>*{
  color: $primary;
}

.sidebar a{
  color: $navbar-item-color !important;
}

.sidebar a.is-active{
  background-color: inherit !important;
}

.icon-button{
  background: transparent;
  border: none !important;
  cursor: pointer;
}
.icon-button>*{
  color: white;
}

.icon-button:hover>*{
  color: $primary;
}
</style>
