  module.exports = {
  title: `Du Sport pour Tous...<br/> En mieux !`,
  subtitle: `Le plan d'entraînement qui s'adapte automatiquement à toutes vos contraintes.`,
  choose_sport: 'Choisis ton sport',
  compatible: 'Compatible avec:',
  partners: 'Nos partenaires:',
  you_are: `Vous êtes <span class="has-text-primary">plutôt ?</span> 🎯`,
  cycling: 'Cyclisme',
  running: 'Running',
  swimming: 'Natation',
  triathlon: 'Triathlon',
  coming_soon: '(coming soon)',
  in_dev: '(En développement)',
  features: `Des <span class="has-text-primary">fonctionnalités</span><br/> adaptées à vos objectifs  🏆`,
  levels: 'Tous niveaux',
  levels_text: `Nous avons développé une solution qui permet à chacun et à chacune, quel que soit votre niveau, de prendre du plaisir dans son sport grâce à notre accompagnement.`,
  simple: 'Simple d\'utilisation',
  simple_text: `L’utilisation de VO2Fast se veut efficace et simple. Notre objectif est de vous faciliter la pratique de votre sport.`,
  customized: 'Sur mesure',
  customized_text: `VO2Fast répond aux objectifs de tous les niveaux ; c’est pourquoi nos offres s’adaptent et nous vous proposons des solutions sur mesure.`,
  efficient: 'Efficace',
  efficient_text: `VO2Fast analyse vos données. Vous pouvez suivre votre évolution et votre plan d'entraînement s'adapte en fonction automatiquement.`,
  try_free: 'Essayez gratuitement',
  how_it_work: 'Fonctionnement',
  w1: 'Choisis tes objectifs',
  w2: 'Définis le nombre de sorties prévues par semaine',
  w3: 'Nous générons ton plan d’entrainement',
  w4: 'Consulte et exporte tes datas',
  our_offers: 'Nos <span class="has-text-primary">offres</span> 🔥',
  free: 'Gratuit',
  standard: 'Standard',
  premium: 'Premium',
  features_list: [
    [ 'Jours d\'entraînement', '3 jours / semaine', 'illimitées', 'illimitées' ],
    //[ 'Changement des dispos', 'limité', 'illimitées', 'illimitées' ],
    [ 'Séances avec Fréquence cardiaque', 'check', 'check', 'check' ],
    [ 'Séances avec Puissance', 'cross', 'cross', 'check' ],
    //[ 'Affichage des étapes et cibles', 'simplifiée', 'simplifiée', 'avancée' ],
    [ 'Données résumé de l’activité', 'données simples', 'données simples', 'avancée' ],
    [ 'Choix du niveau d\'entraînement', 'Débutant seulement', 'check', 'check' ],
    [ 'Choix des objectifs principaux', '1 objectif principal', 'check', 'check' ],
//    [ 'Affichage du parcours', 'check', 'check', 'check' ],
    [ 'Choix des séances home trainer', '1 séance HT / semaine', 'check', 'check' ],
    [ 'Planification de sorties ', 'limité', 'check', 'check' ],
  ],



  any_questions: 'Des Questions ?',
  demo: `Besoin d'une démo ?🤖`,
  demo_text: `Nous restons disponible afin de répondre à tes questions et te présenter notre plateforme. Tu peux nous laisser ton email si tu souhaites une démo du produit ou nous poser des questions par mail: <a href="mailto:contact@vo2fast.com">contact@vo2fast.com</a> <br/>
    Tu peux également nous contacter via la bulle de chat en bas à droite de ton écran.`,

  leave_mail: 'Laissez votre adresse mail et nous vous recontacterons !',


  plans: 'Des plans créés par un expert, une application créée par des passionnés',
  plans_text: `Nos plans d’entraînements sont basés sur les dernières avancées en science du sport et ont été réalisés avec
    l’expertise de Carl Dupont, coach, sport scientist et triathlète de haut niveau.
    L’application est développée par des passionnés de cyclisme.`,

  testimonials: 'Ils utilisent <span class="has-text-primary">VO2Fast</span> ☝️',
  triathlete: 'Triathlète',
  nat_coach: 'Entraîneur national',
  cyclist: 'Cycliste',
  testimonial1: `Simple d'utilisation et très intuitif. Du coaching personnalisé à moindre coût en restant maître de notre emploi du temps.`,
  testimonial2: `Une application qui permet de rester maître de son planning avec des séances d'entraînement spécifiques ! Une grande aide pour la gestion de son planning et un suivi de qualité. Bravo pour cette nouveauté.`,
  testimonial3: `Bravo pour cette innovation sur de l'entraînement personnalisé avec une gestion personnelle de notre emploi du temps. Ravi de pouvoir modifier à mes souhaits les entraînement en fonction de mes dispos et avoir une propositon de nouveau plan automatiquement à chaque fois.`,
  testimonial4: `Après 2 ans ou j'avais l'impression de stagner en m'entraînant par moi même, j'ai passé cet hiver à suivre l'entraînement sur VO2Fast. Les séances spécifiques et la possibilité de suivre un programme flexible à mon emploi du temps m'ont permis d'avoir une meilleure qualité d'entraînement et de gagner 40W sur ma FTP.`,
  testimonial5: `Utilisateur de VO2Fast depuis avril 2022 , je découvre un entraînement plus qualitatif adapté à mes propres zones, des entrainements plus courts mais beaucoup plus ciblés sur une zone spécifique avec à la clef de meilleur résultat en compétition
  Bref je recommande l'application qui en plus et très souple d'utilisation.`,


  mail_notified: 'Merci de laisser votre mail, nous vous notifierons dés que nous aurons des nouvelles !',


  //Coming soon page
  sports_not_available: 'Certains sports ne sont <br/><span class="has-text-primary">pas encore disponibles</span> 😞',
  notify_me_title: 'Je souhaite participer à <br/> la beta 🔔',
  notify_me_text: `Nous sommes actuellement en train de développer ce nouveau module. <br/>
    Vous souhaitez être informé de la sortie de celui-ci ou participer à la beta ? Entrez votre adresse mail ci-dessous pour être prévenu de la date de sortie !`,
  notify_me: 'Me notifier',


  //What cyclist are you page
  step: 'Etape',
  how_many_rides: 'Combien de <span class="has-text-primary">sorties par semaines</span> fais tu en moyenne ? ⭐️',
  kind_of_cyclist: 'Quel <span class="has-text-primary">type de cycliste</span> es-tu ?',
  select_kind: `Selectionne le type de cycliste que tu es et nous trouverons le plan le plus adapté pour toi.`,
  competitive: 'Coureur de compétition',
  gran_fondo: 'Cyclosportif',
  fitness: 'Fitness',
  improve: 'Je veux m\'améliorer',
  see_plan: 'Voir mon plan !',

  //Plan result
  see_other_plans: 'Voir les autres plans',
  best_plan: `Voici le <span class="has-text-primary">meilleur</span> plan pour vous ! 🏆`,
  best_plan_text: `Sur la base de vos réponses précédentes, nous avons trouvé le meilleur plan pour atteindre vos objectifs !`,
  why_choose_plan: 'Pourquoi choisir ce plan ?',
  what_you_need: 'Ce dont tu as besoin',
  advantages: 'Avantages',
  best_free1: `Tu débutes le cyclisme ou tu n’as pas beaucoup de temps à y consacrer.`,
  best_free2: ['Un accompagnement simple et clair', 'Comprendre les données', 'Avoir la version mobile'],
  best_free3: `Grâce à ce type de plan tu prends du plaisir dans ta pratique tout en te maintenant en forme. Tu as accès gratuitement aux fonctionnalités de base dont tu as beoin au quotidien.`,
  best_standard1: `Tu consacres beaucoup de temps au cyclisme et tu souhaites passer un cap dans tes performances.`,
  best_standard2: ['Un accompagnement simple et clair', 'Avoir le choix de tes dispos et les modifier'],
  best_standard3: `Grâce à ce type de plan tu peux commencer à utiliser l’outil de manière intuitive et guidée. Tu as accès à toutes les fonctionnalités de la plateforme (certaines sont simplifiées).`,
  best_premium1: `Tu es un coureur aguerri, tu participes à des compétitions et tu recherches à améliorer tes performances.`,
  best_premium2: ['Un accompagnement simple et clair', 'Toutes les fonctionnalités sans limitation'],
  best_premium3: `Grâce à ce type de plan tu as accès au plein potentiel de la plateforme ce qui te permettra de progresser et d’améliorer tes résultats.`,
};
