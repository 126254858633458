module.exports = `<h1>Privacy Policy of VO2Fast</h1>
  <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the VO2Fast website.</p>
  <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

  <h2>Information Collection and Use</h2>
  <p>Through your use of the Services, we may collect the following "Personal Data" from you if you choose to provide it, including (but not limited to) :</p>
  <ul>
    <li>Your email,  address or other contact information</li>
    <li>Data imported from third parties such as heart rate or other physical response data you choose to import</li>
    <li>Your username, password,  profile picture, gender, current-weight, display preference, resting heart rate, max heart rate, threshold heart rate, threshold power, max power, anaerobic capacity</li>
  </ul>

  <h2>Google Analytics</h2>
  <p>We use Google Analytics, a web analytics service provided by Google, Inc.  Google Analytics collects information such as how often users visit this site, what pages they visit when they do so, and what other sites they used prior to coming to this site. We use the information we get from Google Analytics only to improve this site. Google Analytics may collect information such as your IP address, time of visit, whether you are a returning visitor, and any referring website. Google Analytics do not gather information that personally identifies you. The information generated by Google Analytics will be transmitted to and stored by Google and will be subject to Google's <a href="https://policies.google.com/privacy?hl=en">privacy policies</a>. To learn more about Google's partner services and to learn how to opt-out of tracking of analytics by Google, click <a href="https://policies.google.com/technologies/partner-sites">here</a>.</p>

  <h2>Cookies</h2>
  <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.</p>
  <p>Our website uses these "cookies" to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>
  <p>For more general information on cookies, please read <a href="https://www.cookieconsent.com/what-are-cookies/">"What Are Cookies"</a>.</p>

  <h2>Service Providers</h2>
  <p>We may employ third-party companies and individuals due to the following reasons:</p>
  <ul>
   <li>To facilitate our Service;</li>
   <li>To provide the Service on our behalf;</li>
   <li>To perform Service-related services; or</li>
   <li>To assist us in analyzing how our Service is used.</li>
  </ul>
  <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
  <h2>Security</h2>
  <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

  <h2>Links to Other Sites</h2>
  <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

  <h2>Children privacy</h2>
  <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

  <h2>Changes to This Privacy Policy</h2>
  <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>

  <h2>Contact Us</h2>
  <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us via email to contact@vo2fast.com</p>
`;
