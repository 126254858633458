module.exports = `
<h1>Mentions légales</h1>

<h2>VO2Fast</h2>
<p>373 Cours de la Somme 33800 Bordeaux</p>
<p>Société par actions simplifiée à capital variable</p>
<p>SIREN (RCS Bordeaux): 907684260</p>
<p>SIRET: 	90768426000017</p>
<p>TVA intercommunautaire: FR19907684260</p>
<p>Capital social: 1600 Euros</p>
<p>Nicolas BOURQUARD, président</p>
<p>Carl DUPONT, directeur général</p>
<p>Contact: contact@vo2fast.com</p>
`;
