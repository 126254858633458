<template>
  <div id="app">

    <div id="email-not-verified" class="hero is-warning" v-if="isLoggedIn && user && !user.email_verified">
      <p class="mt-1">{{ $t('account.email_not_verified') }} <button class="button is-warning is-light is-small" @click="resendEmailVerification()">{{ $t('account.email_verification_resend')}}</button></p>
    </div>


    <div class="columns is-gapless">
      <Navigation class="column is-narrow"/>

      <div id="content" class="column is-flexible hide-overflow"  v-if="canShowContent()"> <!-- Only display content when user datas are loaded or if the user isn't logged in -->
        <router-view @authenticated="setAuthenticated"/>
      </div>
    </div>


    <FooterComponent v-if="!$root.isMobile() || !isLoggedIn"/>

    <!--Modals -->
    <Modals v-if="isLoggedIn"/>

    <!--Cookie notice -->
    <CookieLaw :message="$t('cookies.message')" :buttonText="$t('cookies.got_it')"/>

    <ChatBox/>
    <Feedback/>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import CookieLaw from 'vue-cookie-law';
import ChatBox from '@/components/ChatBox.vue';
import Feedback from '@/components/Feedback.vue';

export default {
  name: 'app',
  metaInfo(){
    return {
      meta: [
        {
          name: 'description',
          content: this.$t('meta.description'),
        },
      ],
    };
  },
  data() {
    return {
      authenticated: false,
    };
  },
  computed : {
    isLoggedIn(){ return this.$store.getters.isLoggedIn; },
    user(){ return this.$store.getters.user; },
    isBusinessAccount(){ return this.$store.getters.isBusinessAccount; }
  },
  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout(route='/') {
      if(!this.isLoggedIn)
        return;
      this.$store.dispatch('logout');
      if(route != this.$router.currentRoute.path)
        window.location.href = route; //this.$router.push(route);
    },

    loadData(){
      if(this.isLoggedIn && !this.user)
        this.$store.dispatch('loadUser').catch(err => { console.error(err); });

      if(this.isLoggedIn && this.$store.getters.loadingStatus != 'success')
        this.$store.dispatch('loadUserData').catch(err => { console.error(err); });
    },

    canShowContent(){
      return !this.isLoggedIn || (this.user && this.$store.getters.loadingStatus == 'success');
    },

    resendEmailVerification(){
      this.post('/resend_email_verification').then().catch(err => console.error(err));
    },

    onLogin(){
      let loginRedirection = localStorage.getItem('loginRedirection');
      console.log(this.user)
      if(this?.user?.account_type === 'BUSINESS'){ //if business account, redirect to business space
        this.$router.push('/business_space')
      }else if(loginRedirection && this.$router.resolve(loginRedirection).route.name != '404'){
        this.$router.push(loginRedirection);
      }else if(this.user.onboarding.show_setup_steps){
        this.$router.push('/setup_steps');
      }else{
        this.$router.push('/dashboard');
      }
      if(loginRedirection)
        localStorage.removeItem('loginRedirection');
    },
  },
  created(){
    console.log('infos: ', this.$metaInfos)
    //Prevent PWA default installer from showing right on page open
    let self = this;
    window.addEventListener('beforeinstallprompt', function(e){
      e.preventDefault();
      self.$root.installPrompt = e; //Store install prompt in root to be called after
    });

    var logout = this.logout;
    console.log('interceptor creation')
    //automatic logout on error 401 (token expiration)
    this.$http.interceptors.response.use(undefined, function(err){
      if (err.response.status === 401){//} && err.response.config && !err.response.config.__isRetryRequest) {
        logout('/login');
      }
      return err;
    });

    this.loadData();

    //On loading chunk error message
    //The page must be reloaded to load the latest scripts
    window.addEventListener('error', e => {
      if(/Loading chunk [\d]+ failed/.test(e.message) || e.message.includes("Uncaught SyntaxError: expected expression, got '<'")){
        let lastReload = localStorage.getItem('lastReload') || 0;
        let time = (new Date()).getTime();
        if(time >= lastReload + 3600){ //Avoid infinite loop by allowing a reload only once per hour
          console.log('Reloaded page because of chunk load error');
          localStorage.setItem('lastReload', time);
          window.location.reload();
        }
        /*this.$buefy.dialog.confirm({
          title: this.$t('errors.page_load.loading_error'),
          message: this.$t('errors.page_load.loading_error_description'),
          confirmText: this.$t('errors.page_load.reload_page'),
          cancelText: this.$t('general.cancel'),
          type: 'is-danger',
          iconPack: 'fa',
          icon: 'exclamation-circle',
          hasIcon: true,
          onConfirm: () => window.location.reload(),
        });*/
      }
    });
  },
  mounted(){
    document.dispatchEvent(new Event('render-event')); //event for spa prerendering plugin
  },

  watch: {
    isLoggedIn(){
      this.loadData();
      if(this.isLoggedIn && this.user)
        this.onLogin();
    },

    isBusinessAccount(){
      if(this.isBusinessAccount) //If business user, redirect him to business space
        this.$router.push('/business_space');
    }
  },
  components: {
    Navigation,
    FooterComponent,
    Modals: () => import('@/components/Modals.vue'),
    CookieLaw,
    ChatBox,
    Feedback,
  },
};
</script>

<style lang="scss">
@import '@/styles/global.scss';

.icon{
  width: 18px;
}

#content{
  min-height: 100%;
  font-family: $family-secondary;
  background: url('~@/assets/background.png') repeat;
}
</style>
