module.exports = `
<section>
  <h1>Getting started with VO2Fast</h1>
  <p>
    VO2Fast is an online platform that helps you analyze your training and help you prepare for your goals<br/>
    To get started, here are a a few steps you can do as soon as you start using VO2Fast:
  </p>
</section>
<section>
  <h3>Import your activities</h2>
    <p>
      For proper analysis, you first need to import your activities. You have the option of either uploading your activity files manually
      via the import button <i class="mdi mdi-file-upload"></i> (you can upload multiple files in one go),
      or to sync with Garmin Connect or Strava in the settings page <i class="mdi mdi-cog"></i>.<br/>
      After a successfull sync, the last 3 months of activities as well as any future activity you complete will be automatically uploaded (The synchronisation can take a few minutes and you will have to reload the page to see the new activity added).<br/>
      You can also manually add an activity on the calendar page in the case you don't have the recorded data.
    </p>
</section>
<section>
  <h3>Measures and models</h2>
    <p>
      From every activity uploaded, VO2Fast will automatically estimate a power model (threshold, max power, anaerobic capacity) and a heart rate model (threshold and max) and update any change detected.
      These values can be used to monitor progress and your training (time spent in power zones, training stress, etc... ).<br/>
      On the calendar page, you will also be able to enter measures (can contain your weight and your resting heart rate to complete the heart rate model).<br/>
      These data are also useful to get proper analysis. Measures data can be updated for each day if needed (allowing you to keep track of change).<br/>
      If one of your activity contains wrong data causing problems with your models, you can choose to make it ignored during the calculation (edit -> check ignore power or heartrate).
    </p>
</section>
<section>
  <h3>Generate your training plan</h3>
  <p>
    On the generate a training plan page, you will be able to generate your own personalized plan.The tool will ask you for some information (your training level, your availability, your goals). Once finisged your plan will be generated in a few minutes.<br/>
    You will then be able to see all the sessions planned during the following weeks on your calendar and your dashboard.<br/>
    For each week, you will be able to modify your availability, the plan will be updated automatically. The plan will be updated every time you change (add or delete) a secondary goal during the week.
  </p>
</section>
<section>
  <h3>Follow your workouts</h3>
  <p>
  If you are synchronized with Garmin connect, the sessions of the next 7 days will be automatically sent to Garmin Connect. You will be able to follow the sessions on your Garmin . <br/>
  You can also export all the sessions in file for Home Trainer (erg/mrc) and for Zwift. For more information on getting the sessions on Zwift, <a href="https://www.zwift.com/news/11792-importing-and-sharing-custom-zwift-workouts">click here.</a>
  </p>
</section>
<section>
  <h3>Monitor your training</h3>
  <p>
    On the performance page, you will be able to track your power profile, training stress, fatigue and form over time. <br/>
    You will also have your best power trends (best average power over a set time period and the date it happened. You can see the activity where this happened by clicking on the value you want to see). <br/>
    Your overall power and heart rate curves will also be available.
  </p>
</section>
`;
