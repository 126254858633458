module.exports = [
  {
    title: `How much VO2Fast cost ?`,
    value: `VO2Fast premium version cost 12.99€ per month or 9.91$ per month if you take the yearly subscription (119€ per year). With VO2Fast premium, you have access to our dynamic training plans. <br/>
    But first, on your signup, you will have a month of free trial ! <br/>
    VO2Fast free version allows you to analyse your activities and let you access your power and heart rate curves with automatic power profile detection.`,
    category: `General questions`,
  },
  {
    title: `What devices do you support ?`,
    value: `VO2Fast allows you to sync with Garmin or Strava to automatically import your activities (Other devices supports will be added later). If needed you can also manually upload your activities.<br/>
    VO2Fast can also send your planned workouts to your Garmin devices. <br/>
    Our workouts can also be exported for home trainer in .zwo (for Zwift), .mrc and .erg`,
    category: `General questions`,
  },
  {
    title: `What do I need to use VO2Fast ?`,
    value: `To do our workouts, you just need a bike 🚴 <br/>
    For more precise training and to benefit from the more advanced features of VO2Fast, it is strongly recommended to have at least a heart rate sensor and even better a power meter (as well as a bike computer allowing you to have the data from your sensors and record your activities).`,
    category: `General questions`,
  },
  {
    title: `I have a question or a problem/bug. How can I contact you ?`,
    value: `If you need, do not hesitate to contact us. To do that, you have several possibilities: <br/>
    <ul class="disc-list">
      <li>With the chat (with the chat button on the bottom right, or via the + button -> chat on mobile). We will answer as fast as we can.</li>
      <li>By mail with this address: <a href="mailto:contact@vo2fast.com">contact@vo2fast.com</a></li>
      <li>Via out <a href="https://chat.whatsapp.com/Clu2LL0NbGPIShtSR2vpz1">Whatsapp group</a></li>
    </ul>`,
    category: `General questions`,
  },
  {
    title: `How can I install VO2Fast on Android ?`,
    value: `VO2Fast is a PWA, which means that it is possible to install it via your browser on Android if it is compatible.<br/>
    On the settings page <i class="mdi mdi-cog"></i>, you will find an install button (unless it's already installed on your device or not compatible), just click it and accept the installation.`,
    category: `General questions`,
  },
  {
    title: `How can I install VO2Fast on iOS (iPhone & iPad) ?`,
    value: `VO2Fast is a PWA, which means that it can be installed via your Safari browser.<br/>
    To do so, simply click on the <i class="mdi mdi-export-variant"></i> button at the bottom of your screen when you are on VO2Fast website and validate.`,
    category: `General questions`,
  },


  {
    title: `How to import my activities ?`,
    value: `You can sync your Garmin or Strava account on the settings page <i class="mdi mdi-cog"></i>. Once this is done, all the activities of the last 30 days and every new activity will be automatically imported. <br/>
    You can also manually upload your activity files with the upload button <i class="mdi mdi-file-upload"></i> (supported format: .fit .gpx .tcx).`,
    category: `Getting started with VO2Fast`,
  },
  {
    title: `What is the power model and why is it useful ?`,
    value: `If you have power data (obtained via a power sensor or a home trainer that captures power), VO2Fast will calculate a power model automatically from your activity data. <br/>
    This power model represents your strengths and weaknesses (max sprint power, FTP, MAP (maximal aerobic power)). It's useful for multiple reasons:
    <ul class="disc-list">
      <li>It allows you to follow your progress over time (on the performances page you can see your profile evolution).</li>
      <li>For your workouts, it allows you to have power targets adapted to your level and your profile.</li>
    </ul>
    <br/>
    In order to have a correct model, it is important to test yourself regularly with maximum efforts of different durations (by default our training plans will propose 20min and 5min tests quite regularly).<br/>
    VO2Fast uses data from the last 90 days to recalculate your model each day, so if you notice any dips in your model, it means you haven't had peak efforts of at least the same level as the previous one over 90 days ago.`,
    category: `Getting started with VO2Fast`,
  },
  {
    title: `How does VO2Fast estimate my FTP ?`,
    value: `VO2Fast computes a complete power model including FTP (see above) from your power data for the last 90 days. <br/>
    Note that we do not use the 95% best 20-minute power formula that you may be familiar with. The problem with this formula is that it is imprecise and does not take into account your profile type. (For example, someone with a very high anaerobic capacity will have an FTP below 95% of the 20min power while someone with an aerobic capacity much more developed than their anaerobic capacity may have an FTP a little higher than 95% of the 20min power). <br/>
    To better take your profile into account, VO2Fast uses two maximal efforts, one long and one short (by default in our plans propose 20min and 5min tests).`,
    category: `Getting started with VO2Fast`,
  },
  {
    title: `I just had a new 5min power record and my FTP dropped, why ?`,
    value: `To compute your FTP, VO2Fast uses two maximal efforts (one long and one short, see above for more details on the calculation method).<br/>
    When you break a power record over a short duration, VO2Fast will find that your anaerobic capacity is higher than previously estimated (either because you were missing a real short maximal effort previously or because you have progressed in this area). A higher anaerobic capacity means that your FTP is a slightly lower fraction of your best power over a long effort. <br/>
    Seeing a drop in your FTP does not necessarily mean that you have regressed, in this case, it is rather that VO2Fast now better estimates your true FTP with this new power record.`,
    category: `Getting started with VO2Fast`,
  },
  {
    title: `I have an activity with bad power/FC data that skews my models, what should I do?`,
    value: `If an activity with incorrect data is causing problems, you can flag it as an activity to be ignored. <br/>
    To do this in the calendar, right click on the activity in question (or click on the 3 dots <i class="mdi mdi-dots-vertical"></i> at the top right of the activity) -> edit the activity. Check the "ignore power data" (or heart rate) box and then submit to save the change.`,
    category: `Getting started with VO2Fast`,
  },


  {
    title: `How does VO2Fast training plans works ?`,
    value: `VO2Fast training plans are generated based on your objectives, availabilities and your training level. Our plans are dynamics which means it will adapt automatically to any change. For example you can change your training time for a week, or plan a secodary race or a group ride. The plan will automatically adapt every time. <br/>
    To start your first training plan, you can go to the "generate a training plan" page, fill some infos, your global availabilities (that you will be able to change for each week if needed), your main objectives (secondary objectives can be added later) and start the plan generation. It will be available after a few minutes.`,
    category: `Training plans`,
  },
  {
    title: `I can't train on a certain day or I have a schedule change. What can I do?`,
    value: `On the calendar page, you can modify your availabilities via the add button. Choose the week where you want to modify your availabilities and update them, then click on "Regenerate week". <br/>
    Your plan for the week will be automatically modified in a few seconds to take into account these changes.`,
    category: `Training plans`,
  },
  {
    title: `Can I add or change my goals (races) during the plan?`,
    value: `Yes, you can manage your goals either from the goals tab on your profile or from the calendar. Each time you change your goals, your training plan will be automatically updated to take them into account and adapt the workouts. <br/>
    For the main objectives (A), we advise you to define them when starting a plan because changes during training will require the recalculation of your training blocks, which may not be optimal. Ideally, changes in the main objectives should be made only when necessary. <br/>
    For the secondary goals (B and C), we recommend that you make the changes on your calendar a few days before the date of the goal in question so that the plan can be adapted to the days preceding this goal. You can make as many changes to the secondary goals as you want during the course of the plan without it being a problem.`,
    category: `Training plans`,
  },
  {
    title: `I have a group ride planned, how can I adapt the plan?`,
    value: `If you have a specific ride scheduled such as a group ride, you can add it from the calendar via the add button -> Training -> Training ride. <br/>
    You will need to add an estimate of the duration and intensity you have planned for this ride, the week's plan will then automatically adapt accordingly.`,
    category: `Training plans`,
  },
  {
    title: `How can I start a workout on my Garmin device ?`,
    value: `If your account is synced with Garmin Connect (Doable on the settings page <i class="mdi mdi-cog"></i>. Make sure you allow VO2Fast to push workouts while syncing), the workouts of the next 7 days will be automatically send on Garmin connect. <br/>
    You will then be able to start the workout of the day on your Garmin device.`,
    category: `Training plans`,
  },
  {
    title: `How can I start a workout on Zwift ?`,
    value: `All the VO2Fast workouts can be exported for Zwift. To do that, go to the workout details, click on the export button and export the workout in the .zwo format. Once you have the .zwo file, you can put it in your Zwift workout folder (My documents > Zwift > Workouts > Your Zwift user id).<br/>
    For more informations, <a href="https://www.zwift.com/news/11792-importing-and-sharing-custom-zwift-workouts" target="_blank" rel="noopener noreferrer">you can read this.</a>`,
    category: `Training plans`,
  },
];
