module.exports = [
  {
    title: `Combien coûte VO2Fast ?`,
    value: `La version premium de VO2Fast coûte 12.99€ par mois ou 9.91€ par mois si vous prenez un abonnement à l'année (119€ l'année). Avec VO2Fast premium vous avez accès à nos plans d'entraînements dynamiques. <br/>
    Lors de votre inscription vous aurez accès à 1 mois d'essai gratuit ! <br/>
    La version gratuite de VO2Fast vous permet d'analyser vos sorties et vos courbes de puissances/fréquence cardiaque mais ne vous permet pas de générer des plans d'entraînements.`,
    category: `Questions générales`,
  },
  {
    title: `Quels systèmes supportez vous ?`,
    value: `VO2Fast permet la connexion à Garmin et Strava pour l'importation automatique de vos activités. (D'autres supports seront ajoutés). Si besoin vous pouvez également télecharger vos activités manuellement. <br/>
    VO2Fast peut également envoyer vos séances automatiquement sur vos devices Garmin. <br/>
    Nos séances d'entraînement sont également exportable pour home trainer en .zwo (pour Zwift), .mrc et .erg`,
    category: `Questions générales`,
  },
  {
    title: `De quoi ai-je besoin pour utiliser VO2Fast ?`,
    value: `Pour suivre nos entraînements, vous avez juste besoin d'un vélo 🚴 <br/>
    Pour plus de précision dans les entraînements et pouvoir bénificier des fonctionnalités plus avancées de VO2Fast, il est tout de même vivement conseillé de posséder au moins un capteur de fréquence cardiaque et encore mieux un capteur de puissance si vous en avez un (Ainsi qu'un compteur vous permettant d'avoir les données de vos capteurs et d'enregistrer vos activités).`,
    category: `Questions générales`,
  },
  {
    title: `J'ai une question ou un problème/bug, comment puis-je vous contacter ?`,
    value: `Si vous avez besoin, n'hesitez surtout pas à nous contacter. Pour cela vous avez plusieurs possibilités: <br/>
    <ul class="disc-list">
      <li>Par le chat (via la bulle en bas à droite, ou sur mobile via le bouton + -> chat). Nous vous répondrons aussi rapidement que possible.</li>
      <li>Par mail à cette adresse: <a href="mailto:contact@vo2fast.com">contact@vo2fast.com</a></li>
      <li>Via notre groupe <a href="https://chat.whatsapp.com/HxITLX4mU1dCeXC4rPQ3so">Whatsapp</a></li>
    </ul>`,
    category: `Questions générales`,
  },
  {
    title: `Comment installer VO2Fast sur Android ?`,
    value: `VO2Fast est une PWA, ce qui signifie qu'il est possible de l'installer via votre navigateur sur Android si celui-ci est compatible.<br/>
    Sur la page paramètres <i class="mdi mdi-cog"></i>, vous trouverez un bouton d'installation (sauf si VO2Fast est déjà installé ou incompatible avec votre Android), il suffit de cliquer dessus et d'accepter l'installation.`,
    category: `Questions générales`,
  },
  {
    title: `Comment installer VO2Fast sur iOS (iPhone & iPad) ?`,
    value: `VO2Fast est une PWA, ce qui signifie qu'il est possible de l'installer via votre navigateur Safari.<br/>
    Pour cela il vous suffit de cliquer sur le bouton <i class="mdi mdi-export-variant"></i> en bas de votre écran lorsque vous êtes sur le site de VO2Fast et de valider.`,
    category: `Questions générales`,
  },



  {
    title: `Comment importer mes activités ?`,
    value: `Vous pouvez synchroniser vos comptes Garmin ou Strava depuis la page des paramètres <i class="mdi mdi-cog"></i>. Une fois cela fait, les activités des 30 derniers jours ainsi que toute les nouvelles activités seront automatiquement importées. <br/>
    Vous pouvez également importer manuellement vos fichiers d'activités avec le bouton d'upload <i class="mdi mdi-file-upload"></i> (formats supportés: .fit .gpx .tcx).`,
    category: `Commencer avec VO2Fast`,
  },

  {
    title: `Qu'est-ce que le modèle de puissance et à quoi est-il utile ?`,
    value: `Si vous avez des données de puissances (obtenue via un capteur de puissance ou un home trainer qui capte la puissance), VO2Fast vous calculera un modèle de puissance automatiquement à partir de vos données d'activités. <br/>
    Ce modèle de puissance représente vos forces et vos faiblesses (Sprint max, FTP, PMA). Il est utile pour plusieurs raisons:
    <ul class="disc-list">
      <li>Il vous permet de suivre votre progression dans le temps (sur la page performances vous pouvez voir votre évolution).</li>
      <li>Pour les séances d'entraînements, il vous permet d'avoir des cibles de puissances adaptés à votre niveau et à votre profil.</li>
    </ul>
    <br/>
    Afin d'avoir un modèle qui soit correct, il est important de se tester régulièrement avec des efforts maximum de différentes durées (par défaut les plans d'entraînements vous proposerons des tests de 20min et 5min assez régulièrement).<br/>
    VO2Fast utilise les données des 90 derniers jours pour le recalcul de votre modèle chaque jour donc si vous remarquez des baisses dans votre modèle, c'est que vous n'avez pas eu d'efforts maximums de niveau au moins égal au précédent il y a plus de 90 jours.`,
    category: `Commencer avec VO2Fast`,
  },
  {
    title: `Comment VO2Fast estime-t-il ma FTP ?`,
    value: `VO2Fast calcule un modèle de puissance complet dont la FTP (voir plus haut) à partir de vos données de puissances des 90 derniers jours. <br/>
    A noter que nous n'utilisons pas la formule de 95% de la meilleure puissance de 20 minutes auxquels vous êtes peut être familier. Le problème de cette formule est qu'elle est imprécise et qu'elle ne prend pas en compte votre profil. (Par exemple quelqu'un ayant une trés forte capacité anaérobique aura une FTP inférieure à 95% de la puissance de 20min tandis qu'à contrario quelqu'un avec une capacité aerobie bien plus développé que sa capacité anaérobique pourra avoir une FTP un peu plus haute que 95% de la puissance de 20min).<br/>
    Pour mieux prendre votre profil en compte, VO2Fast utilise deux efforts maximaux, un long et un court (par défaut dans nos plans nos plans proposent des tests de 20min et 5min).`,
    category: `Commencer avec VO2Fast`,
  },
  {
    title: `Je viens de faire un nouveau record de puissance de 5min et ma FTP a baissé, pourquoi ?`,
    value: `Pour calculer votre FTP, VO2Fast utilise deux efforts maximaux (un long et un court, voir plus haut pour plus de détails sur la méthode de calcul).<br/>
    Lorsque vous battez un record de puissance sur une durée courte, VO2Fast constatera que votre capacité anaerobique est plus forte que précedemment estimée (soit parce qu'il manquait un vrai effort court précedemment, soit parce que vous avez progressé dans cette filière). Une capacité anaerobique plus forte veut dire que votre FTP est une fraction un peu plus basse de votre meilleure puissance sur un effort long. <br/>
    Voir une baisse de FTP ne veut donc pas forcément dire que vous avez régressé, dans ce cas-ci c'est plutôt que VO2Fast estime maintenant mieux votre vraie FTP avec ce nouveau record de puissance.`,
    category: `Commencer avec VO2Fast`,
  },
  {
    title: `J'ai une activité avec des mauvaises données de puissances/FC qui faussent mes modèles, que dois-je faire ?`,
    value: `Si une activité avec des données incorrectes pose problème, vous pouvez la signaler comme activité à ignorer. <br/>
    Pour cela dans le calendrier, faites un clic droit sur l'activité en question (ou clic sur les 3 points <i class="mdi mdi-dots-vertical"></i> en haut à droite de l'activité) -> éditer l'activité. Cochez la case "ignorer la puissance" (ou la fc) puis envoyez pour sauvegarder le changement.`,
    category: `Commencer avec VO2Fast`,
  },




  {
    title: `Les plans d'entraînements dynamiques VO2Fast, comment ça marche ?`,
    value: `Les plans d'entraînements VO2Fast sont générés en fonction de vos objectifs, de vos disponibilités et de votre niveau. Ils s'adaptent automatiquement à chaque changement, c'est à dire que vous pouvez changer les horaires d'entraînements de votre semaine, planifier une course secondaire ou une sortie de groupe, etc... à chaque fois le plan sera recalculé. <br/>
    Pour démarrer votre premier plan, vous avez juste à aller sur la page générer un plan d'entraînement, remplir quelques informations, vos disponibilités globales (que vous pourrez ensuite adapter semaine par semaine), vos objectifs principaux (les objectifs secondaires pourront être ajouté par la suite) et lancer la génération de plan. Il sera disponible en quelques minutes.`,
    category: `Plans d'entraînements`,
  },
  {
    title: `Je ne peux pas m'entraîner tel jour ou j'ai un changement d'horaire. Que faire ?`,
    value: `Sur la page calendrier, vous pouvez modifier vos disponibilités via le bouton d'ajout. Choisissez la semaine ou vous souhaitez modifier vos disponibilités et mettez les à jour, puis cliquez sur "Regénérer la semaine". <br/>
    Votre plan de la semaine sera automatiquement modifié en quelques secondes pour prendre en compte ces changements.`,
    category: `Plans d'entraînements`,
  },
  {
    title: `Est-ce que je peux rajouter ou changer mes objectifs (courses) en cours de plan ?`,
    value: `Oui, vous pouvez gérer vos objectifs soit depuis la page d'objectifs sur votre profil soit depuis le calendrier. A chaque changement votre plan d'entraînement se mettra à jour automatiquement pour les prendre en compte et adapter les séances. <br/>
    Pour les objectifs principaux (A), nous vous conseillons de bien les définirs au début du plan car les changements en cours d'entraînement force à recalculer vos blocs d'entraînements ce qui peut ne pas être optimal, idéalement les changements d'objectifs principaux doivent être fait que quand c'est nécessaire. <br/>
    Pour les objectifs secondaires (B et C), nous vous conseillons de faire les changements sur votre calendrier quelques jours avant la date de l'objectif en question pour que le plan puisse s'adapter sur les jours précédent cet objectif. Vous pouvez faire autant de changement d'objectifs secondaire que vous voulez en cours de plan sans que cela pose problème.`,
    category: `Plans d'entraînements`,
  },
  {
    title: `J'ai une sortie en groupe de prévue, comment adapter le plan ?`,
    value: `Si vous avez une sortie spécifique de prévue comme une sortie en groupe, vous pouvez l'ajouter depuis le calendrier via le bouton d'ajout -> Entraînement -> Sortie planifiée. <br/>
    Il vous faudra ajouter une estimation de la durée et de l'intensité que vous avez prévu pour cette sortie, le plan de la semaine s'adaptera ensuite automatiquement en fonction.`,
    category: `Plans d'entraînements`,
  },
  {
    title: `Comment suivre les séances sur mon Garmin ?`,
    value: `Si vous êtes synchronisé avec Garmin connect (faisable sur la page paramètres <i class="mdi mdi-cog"></i>, lors de la synchronisation assurez vous d'autoriser VO2Fast à pousser les séances), les séances des 7 prochains jours seront automatiquement envoyé sur Garmin Connect. <br/>
    Vous pourrez ainsi suivre les séances sur votre appareil Garmin. Pour plus d'informations, <a href="https://blog.vo2fast.com/suivre-une-seance-sur-garmin/" target="_blank">vous pouvez lire cet article.</a>`,
    category: `Plans d'entraînements`,
  },
  {
    title: `Comment suivre les séances sur Zwift ?`,
    value: `Toutes les séances VO2Fast sont exportable sur Zwift. Pour cela il vous suffit de vous rendre sur les détails de la séance, de cliquer sur le bouton exporter et d'exporter la séance en format .zwo . Une fois le fichier .zwo obtenu vous pouvez le glisser dans le dossier workout de Zwift (Mes documents > Zwift > Workouts > Votre ID Zwift) <br/>
    Pour plus d'informations, <a href="https://blog.vo2fast.com/suivre-une-seance-sur-zwift/" target="_blank">vous pouvez lire cet article.</a>`,
    category: `Plans d'entraînements`,
  },
];
