module.exports = {
  general: {
    hi: 'Bonjour',
    thanks: 'Merci',
    fallback: 'Si le bouton au dessus ne fonctionne pas, copier collez l\'URL en dessous dans votre navigateur.',
    vo2fast_team: 'La team VO2Fast',
  },
  email_verification: {
    subject: '[VO2Fast] Vérification de votre adresse email.',
    preheader: 'Utilisez ce lien pour vérifier votre adresse email.',
    requested: 'Merci d\'avoir choisi VO2Fast. <br/> Pour terminer votre inscription, utilisez le bouton en dessous pour vérifier votre adresse email.',
    verify: 'Vérifier',
  },
  free_trial_about_to_end: {
    subject: '[VO2Fast] Ta période d\'essai va bientôt se terminer !',
    free_trial_end: `<b>Ta période d'essai est sur le point de se terminer !</b> <br/>
    Dans moins de 5 jours, tu va devoir t'abonner à VO2Fast si tu veux continuer à utiliser nos plans d'entraînement.<br/>
    Tu peux t'abonner à VO2Fast en cliquant ici 👇`,
    subscribe: 'S\'abonner à VO2Fast',
  },
  free_trial_end: {
    subject: '[VO2Fast] Ta période d\'essai s\'est terminée !',
    free_trial_end: `<b>Ta période d'essai vient de se terminer !</b> <br/>
    Si tu veux continuer à utiliser nos plans d'entraînement tu va devoir t'abonner à VO2Fast.<br/>
    Tu peux t'abonner à VO2Fast en cliquant ici 👇`,
    subscribe: 'S\'abonner à VO2Fast',
  },
  delete_account: {
    subject: 'Suppression de votre compte VO2Fast',
    preheader: 'Utilisez ce lien pour supprimer votre compte. Ce lien n\'est valide qu\'une heure.',
    requested: 'Vous avez récemment demandé la suppression de votre compte VO2Fast. Utilisez le bouton en dessous pour le supprimer. <strong>Ceci n\'est valide que pendant la prochaine heure.</strong>',
    delete: 'Supprimer votre compte'
  },
  reset_password: {
    subject: 'Réinitialisation de mot de passe VO2Fast',
    preheader: 'Utilisez ce lien pour réinitialiser votre mot de passe. Ce lien n\'est valide qu\'une heure.',
    requested: 'Vous avez récemment demandé la réinitialisation de votre mot de passe pour votre compte VO2Fast. Utilisez le bouton en dessous pour le réinitialiser. <strong>Ceci n\'est valide que pendant la prochaine heure.</strong>',
    reset: 'Réinitialiser votre mot de passe',
    ignore: 'Si vous n\'avez pas demandé une réinitialisation de votre mot de passe, ignorez cet email.'
  },
  reset_password_not_found: {
    preheader: 'Nous avons reçu une demande de réinitialisation de mot de passe avec cette adresse email.',
    requested: 'Nous avons reçu une demande de réinitialisation de mot de passe pour l\'accès à un compte VO2Fast avec votre adresse email',
    requested2: 'mais nous n\'avons pas pu trouver de compte associé à cette adresse email.',
    requested3: 'Si vous utilisez VO2Fast et que vous attendiez cet email, cela veut dire que votre compte VO2Fast est lié à une autre adresse email.',
    try: 'Essayer avec une autre adresse email',
    ignore: 'Si vous n\'utilisez pas VO2Fast ou si vous n\'avez pas demandé de réinitialisation de mot de passe, ignorez cet email.'
  },
  notifications: {
    stop: 'Si vous ne voulez plus de ces mails de notifications, vous pouvez les désactiver ici: https://vo2fast.com/settings',
  },
  referrals: {
    notification_subject: '[VO2Fast] Votre code de parrainage a été utilisé',
    notification: ` vient tout juste de s'inscrire avec ton code de parrainage.`,
    notification_paid_subject: '[VO2Fast] Un de vos parrainnés vient de s\'abonner !',
    notification_paid: ` vient tout juste de prendre un abonnement à VO2Fast, vous allez donc bénificier d'un mois gratuit supplémentaire à votre abonnement.`,
  }
};
