const LandingPage = () => import('@/views/landing/Landing.vue'); //import('@/views/others/LandingPage.vue');
const LandingCycling = () => import('@/views/landing/LandingCycling.vue');
const ChoosePlan = () => import('@/views/landing/ChoosePlan.vue');
const BestPlan = () => import('@/views/landing/BestPlan.vue');
const SportComingSoon = () => import('@/views/landing/SportComingSoon.vue');
const FeaturesList = () => import('@/views/landing/FeaturesList.vue');

const Offline = () => import('@/views/others/Offline.vue');
const Team = () => import('@/views/others/Team.vue');
const Features = () => import('@/views/others/Features.vue');
const News = () => import('@/views/social/News.vue');


const DashBoard = () => import(/* webpackChunkName: "group-app" */ '@/views/dashboard/DashBoard.vue');
const Calendar = () => import(/* webpackChunkName: "group-app" */ '@/views/calendar/Calendar.vue');
const Activity = () => import(/* webpackChunkName: "group-app" */ '@/views/activity/Activity.vue');
const Performances = () => import(/* webpackChunkName: "group-app" */ '@/views/performance/Performances.vue');
const Profile = () => import(/* webpackChunkName: "group-app" */ '@/views/account/Profile.vue');
const Settings = () => import(/* webpackChunkName: "group-app" */ '@/views/account/Settings.vue');
const Referrals = () => import(/* webpackChunkName: "group-app" */ '@/views/account/Referrals.vue');
const RedeemCode = () => import(/* webpackChunkName: "group-app" */ '@/views/account/RedeemCode.vue');
const Workout = () => import(/* webpackChunkName: "group-app" */ '@/views/workout/Workout.vue');
const WorkoutCreator = () => import(/* webpackChunkName: "group-app" */ '@/views/workout/WorkoutCreator.vue');
const WorkoutLibrary = () => import(/* webpackChunkName: "group-app" */ '@/views/workout/WorkoutLibrary.vue');
const GenerateTrainingPlan = () => import(/* webpackChunkName: "group-app" */ '@/views/plan/GenerateTrainingPlan.vue');
const SetupSteps = () => import(/* webpackChunkName: "group-app" */ '@/views/setup/SetupSteps.vue');


const PlanData = () => import(/* webpackChunkName: "group-coach" */ '@/views/plan/PlanData.vue');
const Search = () => import(/* webpackChunkName: "group-coach" */ '@/views/social/Search.vue');
const AthleteView = () => import(/* webpackChunkName: "group-coach" */ '@/views/social/AthleteView.vue');
const CoachingView = () => import(/* webpackChunkName: "group-coach" */ '@/views/social/CoachingView.vue');
const AcceptCoach = () => import(/* webpackChunkName: "group-coach" */ '@/views/social/AcceptCoach.vue');


const BlockEditor = () => import(/* webpackChunkName: "group-admin" */ '@/views/adminTools/blockEditor/BlockEditor.vue');
const MailSender = () => import(/* webpackChunkName: "group-admin" */ '@/views/adminTools/mailSender/MailSender.vue');
const Stats = () => import(/* webpackChunkName: "group-admin" */ '@/views/adminTools/Stats.vue');
const CodesManager = () => import(/* webpackChunkName: "group-admin" */ '@/views/adminTools/CodesManager.vue');


const BusinessSignUp = () => import('@/views/business/BusinessSignUp.vue');
const BusinessSpace = () => import(/* webpackChunkName: "group-business" */ '@/views/business/BusinessSpace.vue');
const BusinessSettings = () => import(/* webpackChunkName: "group-business" */ '@/views/business/BusinessSettings.vue');



const Login = () => import('@/views/account/Login.vue');
const SignUp = () => import('@/views/account/SignUp.vue');
const EmailVerification = () => import('@/views/account/EmailVerification.vue');
const Forgot = () => import('@/views/account/Forgot.vue');
const DeleteAccount = () => import('@/views/account/DeleteAccount.vue');
const ResetPassword = () => import('@/views/account/ResetPassword.vue');
const About = () => import('@/views/others/About.vue');
const PrivacyPolicy = () => import('@/views/others/PrivacyPolicy.vue');
const Terms = () => import('@/views/others/Terms.vue');
const Legal = () => import('@/views/others/Legal.vue');
const NotFound = () => import('@/views/others/NotFound.vue');
const Help = () => import('@/views/others/Help.vue');
const LearnMore = () => import('@/views/others/LearnMore.vue');
const Contact = () => import('@/views/others/Contact.vue');
const Tools = () => import('@/views/tools/Tools.vue');


const Syncs = () => import('@/views/account/syncs/Syncs.vue');
const SyncGarmin = () => import('@/views/account/syncs/SyncGarmin.vue');
const SyncStrava = () => import('@/views/account/syncs/SyncStrava.vue');
const SyncWahoo = () => import('@/views/account/syncs/SyncWahoo.vue');


const Subscribe = () => import('@/views/account/subscription/Subscribe.vue');
const SubscriptionSuccess = () => import('@/views/account/subscription/SubscriptionSuccess.vue');

import Vue from 'vue/dist/vue.js';



const siteRoutes = [
  {
    path: '',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: 'cycling',
    name: 'LandingCycling',
    component: LandingCycling
  },
  {
    path: 'choose_plan',
    name: 'ChoosePlan',
    component: ChoosePlan
  },
  {
    path: 'best_plan',
    name: 'BestPlan',
    component: BestPlan
  },
  {
    path: 'pricing',
    name: 'FeaturesList',
    component: FeaturesList,
    props: { showSignups: true, title: 'landing.our_offers' },
  },
  {
    path: 'sport_coming_soon',
    name: 'SportComingSoon',
    component: SportComingSoon
  },
  // {
  //   path: 'team',
  //   name: 'Team',
  //   component: Team,
  // },
  // {
  //   path: 'features',
  //   name: 'Features',
  //   component: Features,
  // },
  {
    path: 'help',
    name: 'help',
    component: Help
  },
  {
    path: 'learn_more',
    name: 'learn-more',
    component: LearnMore
  },
  {
    path: 'about',
    name: 'about',
    component: About,
  },
  {
    path: 'privacy_policy',
    name: 'privacy_policy',
    component: PrivacyPolicy,
  },
  {
    path: 'terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: 'legal',
    name: 'legal',
    component: Legal,
  },
  {
    path: 'contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: 'news',
    name: 'News',
    component: News,
  },

];

const appRoutes = [
  {
    path: 'offline',
    name: 'offline',
    component: Offline
  },
  {
    path: 'tools',
    name: 'tools',
    component: Tools
  },
  {
    path: 'tools/:tool',
    name: 'tools',
    component: Tools,
    meta: {
      sitemap: { slugs: ['ftp_estimator'], },
    },
  },
  {
    path: 'dashboard',
    name: 'dashboard',
    component: DashBoard,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'performances',
    name: 'performances',
    component: Performances,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'referrals',
    name: 'referrals',
    component: Referrals,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: 'redeem_code',
    name: 'RedeemCode',
    component: RedeemCode,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: 'subscribe',
    name: 'subscribe',
    component: Subscribe,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: 'subscription_success',
    name: 'subscription-success',
    component: SubscriptionSuccess,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: 'syncs',
    name: 'Syncs',
    component: Syncs,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'sync_garmin',
    name: 'SyncGarmin',
    component: SyncGarmin,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'sync_strava',
    name: 'SyncStrava',
    component: SyncStrava,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'sync_wahoo',
    name: 'SyncWahoo',
    component: SyncWahoo,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'activity',
    name: 'activity',
    component: Activity,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'workout',
    name: 'workout',
    component: Workout,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'workout_creator',
    name: 'workout-creator',
    component: WorkoutCreator,
    props: true,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'workout_library',
    name: 'workout-library',
    component: WorkoutLibrary,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'setup_steps',
    name: 'setup-steps',
    component: SetupSteps,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'generate_training_plan',
    name: 'generate-training-plan',
    component: GenerateTrainingPlan,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'training_plan_data',
    name: 'plan-data',
    component: PlanData,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: 'search',
    name: 'search',
    component: Search,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: 'athlete/:athlete',
    name: 'athleteview',
    component: AthleteView,
    meta: { sitemap: { ignoreRoute: true }, },
  },
  {
    path: 'coaching_view',
    name: 'coaching-view',
    component: CoachingView,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: 'accept_coach/:coachId',
    name: 'accept-coach',
    component: AcceptCoach,
    meta: { sitemap: { ignoreRoute: true }},
  },
  {
    path: 'login',
    name: 'login',
    component: Login
  },
  {
    path: 'signup',
    name: 'signup',
    component: SignUp
  },
  {
    path: 'business_signup',
    name: 'BusinessSignup',
    component: BusinessSignUp
  },
  {
    path: 'verify_email/:token',
    name: 'email_verification',
    component: EmailVerification,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'forgot',
    name: 'forgot',
    component: Forgot
  },
  {
    path: 'reset_password/:token',
    name: 'reset_password',
    component: ResetPassword,
    meta: { sitemap: { ignoreRoute: true }, },
  },
  {
    path: 'delete_account/:token',
    name: 'delete_account',
    component: DeleteAccount,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'block_editor',
    name: 'BlockEditor',
    component: BlockEditor,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'mail_sender',
    name: 'MailSender',
    component: MailSender,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'stats',
    name: 'Stats',
    component: Stats,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'codes_manager',
    name: 'CodesManager',
    component: CodesManager,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'business_space',
    name: 'BusinessSpace',
    component: BusinessSpace,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
  {
    path: 'business_settings',
    name: 'BusinessSettings',
    component: BusinessSettings,
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    }
  },
];


async function localeBeforeEnter(locale){
  const i18n = (await import('../plugins/vuei18n.js')).default;
  //TODO: if settings locale different, redirect or update ? And language switcher update url
  Vue.set(i18n, 'locale', locale);
  document.documentElement.setAttribute('lang', i18n.locale); //update html lang
}

const localeRoutes = [
  {
    path: 'fr',
    component: {
      template: '<router-view></router-view>',
    },
    beforeEnter(to, from, next) {
      localeBeforeEnter('fr');
      next();
    },
    children: siteRoutes,
  },
  {
    path: 'en',
    component: {
      template: '<router-view></router-view>',
    },
    beforeEnter(to, from, next){
      localeBeforeEnter('en');
      next();
    },
    children: siteRoutes,
  },
];

export const routes = [
  {
    path: '',
    component: {
      template: '<router-view></router-view>',
    },
    children: [
      ...siteRoutes,
      ...localeRoutes,
      ...appRoutes,
      {
        path: '*',
        name: '404',
        component: NotFound
      },
    ],
  },
];

export default routes;
