module.exports = {
  notifications: 'Notifications',
  mail: 'Mail',
  enable: 'Enable',
  enable_devices: 'Enable notifications on this device: ',
  enabled: 'Notifications enabled on this device.',
  not_supported: 'Notifications are not available on your device.',
  see_on_vo2fast: 'See on VO2Fast',
  day_workout: 'Workout of the day',
  day_workout_text: 'Send me a notification/mail each morning with the workout of the day.',
  model_change: 'Model change',
  model_change_text: 'Send me a notification/mail each time a model change (new FTP for example) is detected.',
  subscribed: 'You subscribed to VO2Fast notifications.',
  ftp_update: 'New FTP detected:  {0}W !',
  map_update: 'New MAP detected: {0}W !',
  pmax_update: 'New max power detected: {0}W !',
  hrt_update: 'New Threshold heart rate detected: {0}bpm !',
  hrmax_update: 'New Max heart rate detected: {0}bpm !',
  power_model_update: 'New model: {threshold_power}W FTP, {map}W MAP, {max_power}W Max power, {anaerobic_capacity}Kj anaerobic capacity',
  heart_rate_model_update: 'New model: {threshold_heart_rate}bpm at threshold, {max_heart_rate}bpm max heart rate.',
  day_workout_message: 'Your workout of the day is {workout}.',
  more_details_click_below: 'To see more details click on the link below.',
};
