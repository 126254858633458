/*
 * Date utils function and Local Date class
 * Local Date is a date that ignore time zones
 */

import Vue from 'vue/dist/vue.js';

export function $dateOffset(date = new Date()){ return offset(date); }

//Calc timezone offset of date object
function offset(date = new Date()){ return date.getTimezoneOffset()*60 * 1000; }
const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds


//LocalDate class
export class LocalDate{
  //Constructor, use to copy a local date, parse a date string, or to copy a utc date
  //To convert a local date js object to a localdate (tz removed), use fromDate instead
  constructor(value = Date.now()-offset()){
    if(value instanceof LocalDate) //if object is a localdate, copy its time
      this.date = new Date(value.getTime());
    else if(value instanceof String) //if object is a string, parse date and remove timezone offset
      this.date = new Date(Date.parse(value.split('GMT')[0]) - offset());
    else //Else date is value given
      this.date = new Date(value);
  }
  //static function that call the constructor and return the object
  static new(value = Date.now()-offset()){ return new LocalDate(value); }
  //Convert a local date js object to a LocalDate (tz removed without modifying hours)
  static fromDate(date){ return new LocalDate(date.getTime()-offset(date)); }
  //Same that from date but with a number value
  static fromTimestamp(timestamp){ return LocalDate.fromDate(new Date(timestamp)); }

  //Current date and time as a LocalDate
  static now(){ return new LocalDate(); }
  //Specific date as a localdate object
  static date(year, month = 0, day=1, hours=0, minutes=0, seconds=0, ms=0){
    return new LocalDate(Date.UTC(year, month, day, hours, minutes, seconds, ms));
  }
  //LocalDate day of date(time set to 0, beginning of the day)
  static dayFromDate(date){ return this.date(date.getFullYear(), date.getMonth(), date.getDate()); }
  //Currant day as a LocalDate
  static today(){ return this.dayFromDate(new Date()); }
  //Start of the current week as a LocalDate object
  static thisWeek(){ return this.today().firstDayOfWeek(); }

  //Convert back to a js Date object
  toDateObject(){ return new Date(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds(), this.getMilliseconds()); }


  //Date getters functions
  getDate(){ return this.date.getUTCDate(); }
  getDay(){ return this.date.getUTCDay(); }
  getFullYear(){ return this.date.getUTCFullYear(); }
  getHours(){ return this.date.getUTCHours(); }
  getMilliseconds(){ return this.date.getUTCMilliseconds(); }
  getMinutes(){ return this.date.getUTCMinutes(); }
  getMonth(){ return this.date.getUTCMonth(); }
  getSeconds(){ return this.date.getUTCSeconds(); }

  getTime(){ return this.date.getTime(); }

  //Date setters functions
  setDate(day){ return this.date.setUTCDate(day); }
  setFullYear(year){ return this.date.setUTCFullYear(year); }
  setHours(hours){ return this.date.setUTCHours(hours); }
  setMilliseconds(ms){ return this.date.setUTCMilliseconds(ms); }
  setMinutes(minutes){ return this.date.setUTCMinutes(minutes); }
  setMonth(month){ return this.date.setUTCMonth(month); }
  setSeconds(seconds){ return this.date.setUTCSeconds(seconds); }

  setTime(time){ return this.date.setTime(time); }
  addTime(time){ return this.date.setTime(this.getTime() + time); }

  //Date to a string describing the date
  toLocaleDateString(){
    return this.toDateObject().toLocaleDateString();
  }
  toISOString(){
    return this.date.toISOString();
  }
  toJSON(){
    return this.toISOString();
  }
  toString(){
    return this.date.toUTCString().split('GMT')[0];
  }
  toTimeString(){
    return this.getDateObject().toTimeString().split('GMT')[0];
  }

  valueOf(){
    return this.getTime();
  }


  dayOfWeek(){
    return (this.getDay() || 7)-1; //return 0-6 for monday-sunday (instead of sunday-saturday)
  }

  //Get first day of the week of the LocalDate
  firstDayOfWeek(){
    var date = new LocalDate(this);
    var day = date.getDay() || 7;
    if(day !== 0)
      date.setDate(date.getDate() - (day-1)); //Decrement day to first day
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  //Get last day of the week of the LocalDate
  lastDayOfWeek(){
    var date = new LocalDate(this);
    var day = date.getDay() || 7;
    if(day !== 7)
      date.setDate(date.getDate() + (7-day)); //Increment day to last day
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  firstDayOfMonth(){ return LocalDate.date(this.getFullYear(), this.getMonth(), 1); }
  lastDayOfMonth(){ return LocalDate.date(this.getFullYear(), this.getMonth()+1, 0); }
  firstDayOfYear(){ return LocalDate.date(this.getFullYear(), 0, 1); }
  lastDayOfYear(){ return LocalDate.date(this.getFullYear(), 12, 0); }

  //Check if two date are the same day
  isSameDay(date){
    return this.getFullYear() === date.getFullYear() &&
    this.getMonth() === date.getMonth() &&
    this.getDate() === date.getDate();
  }
  isToday(){ return this.isSameDay(new LocalDate()); }
  isThisWeek(){ return this.firstDayOfWeek().getTime() === (new LocalDate()).firstDayOfWeek().getTime(); }
  isThisMonth(){ return this.firstDayOfMonth().getTime() === (new LocalDate()).firstDayOfMonth().getTime(); }
  isThisYear(){ return this.firstDayOfYear().getTime() === (new LocalDate()).firstDayOfYear().getTime(); }

  daysDiff(date){
    return Math.floor(Math.abs((this.getTime() - date.getTime()) / oneDay)); //TODO : do better
  }
  yearsDiff(date){
    var [d1, d2] = this > date ? [this, date] : [date, this];
    var result = Math.abs(this.getFullYear() - date.getFullYear());
    if(!(this.getMonth() > date.getMonth() || (this.getMonth() == date.getMonth() && this.getDate() >= date.getDate())))
      result --;
    return result;
  }

  addDay(inc=1){
    var date = new LocalDate(this);
    date.setDate(date.getDate()+inc);
    return date;
  }

  //Return a string in the format 'YYYY-MM-DD'
  dateFormat(){
    return this.getFullYear()+'-'+$pad(this.getMonth()+1, 2)+'-'+$pad(this.getDate(), 2);
  }

  toTimestamp(){
    return this.toDateObject();
  }



  [Symbol.toPrimitive](hint) {
    if(hint === 'number')
      return this.valueOf();
    return this.toString();
  }
}


//Increment date day
export function unextDay(date, inc=1){
  const oneDay = 24 * 60 * 60 * 1000;
  return new Date(date.getTime()+oneDay*inc);
}

//Convert time to a string in the format h min s (format can change depending of if there is hours, minutes or seconds, see below)
export function utimeToString(time, alwaysShowSeconds = false){
  if(time === 0 || isNaN(time))
    return '';

  var hours = Math.floor(time/3600);
  var minutes = Math.floor((time/60)%60);
  var seconds = Math.floor((time%60)*1);
  if(hours === 0){
    if(minutes === 0){
      return seconds + 's'; //If only seconds, show only seconds
    }
    return minutes + 'min' + (seconds > 0 ? seconds : ''); //if no hours, show minutes with seconds if there is seconds
  }
  return hours + 'h' + $pad(minutes, 2) + (alwaysShowSeconds && seconds > 0 ? 'min' + seconds : ''); //If hours and minutes, don't show seconds unless specified
}


//Utility function, pad a string number with 0 until length is reached (eg 10 with len 4 -> 0010).
//If len is <= current number length, nothing is done
export function $pad(num, len) {return (Array(len).join("0") + num).slice(-len);}

//Return time as a string in the format 'hh:mm:ss'
export function upreciseTimeToString(time){
  var hours = Math.floor(time/3600);
  var minutes = Math.floor((time/60)%60);
  var seconds = time%60;
  if(hours == 0){
    if(minutes == 0)
      return time+"s";
    return $pad(minutes, 2)+":"+$pad(seconds, 2);
  }
  return hours+":"+$pad(minutes,2)+":"+$pad(seconds, 2);
}

export function mixinDateUtil(){
  Vue.mixin({
    methods: {
      $dateOffset,
      unextDay,
      utimeToString,
      $pad,
      upreciseTimeToString,
    }
  });
}
