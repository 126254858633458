module.exports = {
  title: 'Sport for Everyone...<br/> But better!',
  subtitle: 'Personalized training plans for everyone.',
  choose_sport: 'Choose your sport',
  compatible: 'Compatible with:',
  partners: 'Our partners:',
  you_are: 'What are you <span class="has-text-primary">interested in?</span> 🎯',
  cycling: 'Cycling',
  running: 'Running',
  swimming: 'Swimming',
  triathlon: 'Triathlon',
  coming_soon: '(coming soon)',
  in_dev: '(In development)',
  features: 'Tailored <span class="has-text-primary">features</span><br/> for your goals 🏆',
  levels: 'All levels',
  levels_text: 'We have developed a solution that allows everyone, regardless of your level, to take enjoy practicing their sport thanks to our support.',
  simple: 'Simple',
  simple_text: 'The use of the VO2Fast solution aims to be efficient and simple. Our goal is to facilitate the practice of your sport.',
  customized: 'Customized',
  customized_text: `We aim to meet the goals of all levels; that's why our offers are adaptable and we offer customized solutions.`,
  efficient: 'Efficient',
  efficient_text: 'VO2Fast analyzes your data. You can track your progress and modify your training accordingly.',
  try_free: 'Try for free',
  how_it_work: 'How it works',
  w1: 'Choose your goals',
  w2: 'Set the number of planned sessions per week',
  w3: 'We generate your plan or training advice',
  w4: 'Consult and export your data',
  our_offers: 'Our <span class="has-text-primary">offers</span> 🔥',
  free: 'Free',
  standard: 'Standard',
  premium: 'Premium',
  features_list: [
    ['Training days', '3 days / week', 'Unlimited', 'Unlimited'],
    //['Schedule changes', 'Limited', 'Unlimited', 'Unlimited'],
    ['Heart rate workouts', 'check', 'check', 'check'],
    ['Power workouts', 'cross', 'cross', 'check'],
//    ['Display of steps and targets', 'simplified', 'simplified', 'advanced'],
    ['Activity summary data', 'basic datas', 'basic datas', 'advanced'],
    ['Training level selection', 'Beginner only', 'check', 'check'],
    ['Primary goal selection', '1 main goal', 'check', 'check'],
//    ['Route display', 'check', 'check', 'check'],
    ['Indoor trainer sessions', '1 session HT / week', 'check', 'check'],
    ['Planning of group rides', 'limited', 'check', 'check'],
  ],

  any_questions: 'Any questions?',
  demo: 'Need a demo? 🤖',
  demo_text: 'We are available to answer your questions and present our platform. You can leave your email if you would like a product demo or ask us questions by email: <a href="mailto:contact@vo2fast.com">contact@vo2fast.com</a> <br/> You can also contact us via the chat bubble in the bottom right corner of your screen.',
  leave_mail: 'Leave your email and we will get back to you!',
  plans: 'Plans created by an expert, an application created by enthusiasts',
  plans_text: 'Our training plans are based on the latest advances in sports science and have been developed with the expertise of Carl Dupont, coach, sports scientist, and high-level triathlete. The application is developed by cycling enthusiasts.',
  testimonials: 'They use <span class="has-text-primary">VO2Fast</span> ☝️',
  triathlete: 'Triathlete',
  nat_coach: 'National Coach',
  cyclist: 'Cyclist',
  testimonial1: 'Easy to use and very intuitive. Personalized coaching at a lower cost while staying in control of our schedule.',
  testimonial2: 'An application that allows you to stay in control of your schedule with specific training sessions! A great help for managing your schedule and quality tracking. Congratulations on this innovation.',
  testimonial3: 'Congratulations on this innovation in personalized training with personal schedule management. I am delighted to be able to modify my training according to my availability and receive a new plan proposal automatically each time.',
  testimonial4: 'After 2 years where I felt like I was plateauing by training on my own, I spent this winter following the training on VO2Fast. The specific sessions and the ability to follow a flexible program based on my schedule allowed me to have better training quality and gain 40W on my FTP.',
  testimonial5: 'As a user of VO2Fast since April 2022, I discovered a more qualitative training adapted to my own zones, shorter but much more targeted training sessions with better results in competition (good correlation with the GARMIN app where my training is now always marked as productive, which was not the case before). \nIn short, I recommend the application, which is also very user-friendly.',
  mail_notified: 'Thank you for leaving your email, we will notify you as soon as we have news!',
  sports_not_available: 'Some sports are <br/><span class="has-text-primary">not yet available</span> 😞',
  notify_me_title: 'I want to participate in the <br/> beta release 🔔',
  notify_me_text: 'We are currently developing this new module. <br/> Would you like to be informed of its release or participate in the beta? Enter your email below to be notified of the release date!',
  notify_me: 'Notify me',
  step: 'Step',
  how_many_rides: 'How many <span class="has-text-primary">rides per week</span> do you do on average? ⭐️',
  kind_of_cyclist: 'What kind of <span class="has-text-primary">cyclist</span> are you?',
  select_kind: 'Select the type of cyclist you are, and we will find the most suitable plan for you.',
  competitive: 'Competitive racer',
  gran_fondo: 'Gran Fondo rider',
  fitness: 'Fitness',
  improve: 'I want to improve',
  see_plan: 'See my plan!',
  see_other_plans: 'See other plans',
  best_plan: 'Here is the <span class="has-text-primary">best</span> plan for you! 🏆',
  best_plan_text: 'Based on your previous answers, we have found the best plan to help you achieve your goals!',
  why_choose_plan: 'Why choose this plan?',
  what_you_need: 'What you need',
  advantages: 'Advantages',
  best_free1: `You are new to cycling or don't have much time to dedicate to it.`,
  best_free2: ['Simple and clear guidance', 'Understanding data', 'Having the mobile version'],
  best_free3: 'With this type of plan, you can enjoy your practice while staying fit. You have free access to the basic features you need on a daily basis.',
  best_standard1: 'You dedicate a lot of time to cycling and want to take your performance to the next level.',
  best_standard2: ['Simple and clear guidance', 'Ability to choose and modify your availability'],
  best_standard3: 'With this type of plan, you can start using the tool intuitively and with guidance. You have access to all the features of the platform (some are simplified).',
  best_premium1: 'You are an experienced racer, participate in competitions, and want to improve your performance.',
  best_premium2: ['Simple and clear guidance', 'All features without limitations'],
  best_premium3: 'With this type of plan, you have access to the full potential of the platform, allowing you to progress and improve your results.',
};
