<template>
  <div class="chat-box-template">
  </div>
</template>

<script>
//Smartsupp Live Chat script

export default{
  name: 'chatbox',
  computed: {
    user(){ return this.$store.getters.user; },
    isMobile(){ return this.$root.isMobile(); },
    isChatHidden(){
      return this.$route.path === '/offline' ||
      (this.isMobile && this.$store.getters.isLoggedIn);
    },
  },
  methods: {
    setUserData(){
      if(this.user){
        window.Tawk_API.visitor = {
          name  : this.user.username,
          email : this.user.email,
        };
        //TODO when available in APIchange language this.user.settings?.language || localStorage.getItem('locale') || 'fr'
      }
    },

    updateVisibility(){
      if(!window.Tawk_API.showWidget)
        return;


      if(this.isChatHidden){
        window.Tawk_API.hideWidget();
      }else{
        window.Tawk_API.showWidget();
      }
    }
  },
  mounted(){
    window.Tawk_API = window.Tawk_API || {};
    this.setUserData();
    window.Tawk_API.onLoad = () => {
      this.updateVisibility();
    }
  },
  watch: {
    user(){
      this.setUserData();
    },
    isChatHidden(){
      this.updateVisibility();
    },
  },
};
</script>

<style>
@media only screen and (max-width:767px){
  #chat-application{
    right: 18px !important;
    bottom: 75px !important;
    z-index: 37 !important;
  }
}
</style>
