import axios from 'axios';
import Vue from 'vue/dist/vue.js';
import { LocalDate } from '@/helpers/dateUtil.js';
import { post, get } from '@/plugins/network.js';

function trackWeekRegeneration(){
  try{
    Vue.$gtag.event('week_regeneration', {
      event_category: 'engagement',
    });
  }catch(err){
    console.error(err);
  }
}



const userDataModule = {
  state: {
    loadingStatus: '',
    activities: [],
    measures: {},
    models: {},
    workouts: [],
    objectives: [],
    trainingRides: [],
    trainingBlocks: [],
    loadedUser: localStorage.getItem('loadedUser'),
  },
  mutations:{
    loadRequest(state){
      state.loadingStatus = 'loading';
    },
    loadSuccess(state){
      state.loadingStatus = 'success';
    },
    loadError(state){
      state.loadingStatus = 'error';
    },

    addActivity(state, activity){
      activity.date = new LocalDate(activity.date);
      activity.timestamp = new Date(activity.timestamp);
      state.activities.push(activity);
      state.activities.sort((a1,a2) => a1.date - a2.date);
    },
    removeActivity(state, activity){
      Vue.set(state, 'activities', state.activities.filter(act => act.serial_number != activity.serial_number));
    },
    updateActivity(state, activity){
      if(activity.date)
        activity.date = new LocalDate(activity.date);
      if(activity.timestamp)
        activity.timestamp = new Date(activity.timestamp);
      var i = state.activities.findIndex(a => a.serial_number == activity.serial_number);
      var activityToUpdate = state.activities[i];
      activity.data = Object.assign({}, activityToUpdate?.data, activity.data);
      activityToUpdate = Object.assign({}, activityToUpdate, activity);

      if(i < 0 || i > state.activities.length){
        state.activities.push(activityToUpdate);
        state.activities.sort((a1,a2) => a1.date - a2.date);
      }else{
        Vue.set(state.activities, i, activityToUpdate);
      }
    },

    addMeasure(state, measure){
      measure.date = new LocalDate(measure.date);
      Vue.set(state.measures, measure.date.dateFormat(), measure);
    },
    removeMeasure(state, measure){
      Vue.delete(state.measures, LocalDate.new(measure.date).dateFormat());
    },


    addModel(state, model){
      model.date = new LocalDate(model.date);
      Vue.set(state.models, model.date.dateFormat(), model);
    },
    removeModel(state, model){
      Vue.delete(state.models, LocalDate.new(model.date).dateFormat());
    },

    setModels(state, models){
      state.models = {};
      models.forEach(model => {
        model.date = new LocalDate(model.date);
        Vue.set(state.models, model.date.dateFormat(), model);
      });
    },



    addWorkout(state, workout){
      workout.date = new LocalDate(workout.date);
      state.workouts.push(workout);
      state.workouts.sort((w1,w2) => w1.date.getTime() - w2.date.getTime());
    },
    updateWorkout(state, workout){
      if(workout.date)
        workout.date = new LocalDate(workout.date);
      var i = state.workouts.findIndex(w => w.id == workout.id);
      var workoutToUpdate = state.workouts[i];
      workout.data = Object.assign({}, workoutToUpdate.data, workout.data);
      Object.assign(workoutToUpdate, workout);
      Vue.set(state.workouts, i, workoutToUpdate);
    },
    removeWorkout(state, workout){
      Vue.set(state, 'workouts', state.workouts.filter(w => w.id != workout.id));
    },

    addObjective(state, objective){
      objective.date = new LocalDate(objective.date);
      state.objectives.push(objective);
      state.objectives.sort((o1,o2) => o1.date.getTime() - o2.date.getTime());
    },
    updateObjective(state, objective){
      if(objective.date)
        objective.date = new LocalDate(objective.date);
      var i = state.objectives.findIndex(o => o.id == objective.id);
      if(i>=0){
        var objectiveToUpdate = state.objectives[i];
        Object.assign(objectiveToUpdate, objective);
        Vue.set(state.objectives, i, objectiveToUpdate);
      }else{
        state.objectives.push(objective);
      }
      state.objectives.sort((o1,o2) => o1.date.getTime() - o2.date.getTime());
    },
    removeObjective(state, objective){
      Vue.set(state, 'objectives', state.objectives.filter(o => o.id != objective.id));
    },

    addTrainingRide(state, trainingRide){
      trainingRide.date = new LocalDate(trainingRide.date);
      state.trainingRides.push(trainingRide);
      state.trainingRides.sort((t1,t2) => t1.date.getTime() - t2.date.getTime());
    },
    updateTrainingRide(state, trainingRide){
      if(trainingRide.date)
        trainingRide.date = new LocalDate(trainingRide.date);
      var i = state.trainingRides.findIndex(t => t.id == trainingRide.id);
      if(i>=0){
        var trainingRideToUpdate = state.trainingRides[i];
        Object.assign(trainingRideToUpdate, trainingRide);
        Vue.set(state.trainingRides, i, trainingRideToUpdate);
      }else{
        state.trainingRides.push(trainingRide);
      }
      state.trainingRides.sort((t1,t2) => t1.date.getTime() - t2.date.getTime());
    },
    removeTrainingRide(state, trainingRide){
      Vue.set(state, 'trainingRides', state.trainingRides.filter(t => t.id != trainingRide.id));
    },


    setTrainingBlocks(state, trainingBlocks){
      state.trainingBlocks = [];
      if(!trainingBlocks)
        return;
      for(let block of trainingBlocks){
        block.start = new LocalDate(block.start);
        block.end = new LocalDate(block.end);
        state.trainingBlocks.push(block);
      }
    },

    resetData(state){
      state.activities = [];
      state.measures = {};
      state.models = {};
      state.workouts = [];
      state.objectives = [];
      state.trainingRides = [];
      state.trainingBlocks = [];
      state.loadingStatus = '';
    },

    setLoadedUser(state, user_id){
      state.loadedUser = user_id;
      if(user_id){
        localStorage.setItem('loadedUser', user_id);
        axios.defaults.headers.common['v-loaded-user'] = user_id;
      }else{
        localStorage.removeItem('loadedUser');
        delete axios.defaults.headers.common['v-loaded-user'];
      }
    },


    setSetModel(state, set_model){
      Vue.set(state, 'set_model', set_model);
    },
  },
  actions: {
    async loadUserData({commit, state}){
      try{

        var measures_data = await get('/measures');
        if(!measures_data)
          throw 'no_data';

        var models_data = await get('/models');
        if(!models_data)
          throw 'no_data';

        var activities_data = await get('/activities');
        if(!activities_data)
          throw 'no_data';


        var workouts_data = await get('/workouts');
        if(!workouts_data)
          throw 'no_data';


        var objectives_data = await get('/objectives');
        if(!objectives_data)
          throw 'no_data';



        var training_rides_data = await get('/training_rides');
        if(!training_rides_data)
          throw 'no_data';




        var training_blocks_data = await get('/training_blocks');
        if(!training_blocks_data)
          throw 'no_data';

        var set_model_data = await get('/set_model');
        if(!set_model_data)
          throw 'no_data';


        commit('resetData');
        commit('loadRequest');

        for(let measure of measures_data.measures)
          commit('addMeasure', measure);

        commit('setModels', models_data.models);

        for(let activity of activities_data.activities)
          commit('addActivity', activity);

        for(let workout of workouts_data.workouts)
          commit('addWorkout', workout);

        for(let objective of objectives_data.objectives)
          commit('addObjective', objective);

        for(let trainingRide of training_rides_data.training_rides)
          commit('addTrainingRide', trainingRide);

        commit('setTrainingBlocks', training_blocks_data.training_blocks);

        commit('setSetModel', set_model_data.set_model);


        commit('loadSuccess');
      }catch(err){
        commit('resetData');
        commit('loadError');
        throw err;
      }
    },

    async reloadUserDataAfterSync({dispatch, state}){
      const TIMEINTERVAL = 30000; //Attempt reload every 30sec
      let reload = async function(){
        try{
          let activitiesCount = state.activities.length;
          let modelsCount = state.models.length;
          await dispatch('loadUserData');
          if(state.activities.length !== activitiesCount || state.models.length !== modelsCount)
            setTimeout(reload, TIMEINTERVAL);
        }catch(err){
          console.error(err);
        }
      }
      setTimeout(reload, TIMEINTERVAL);
    },

    newMeasure({dispatch}, measure){
      return dispatch('updateUserData', { action: 'NEW', type: 'measure', data: measure });
    },
    removeMeasure({dispatch}, measure){
      return dispatch('updateUserData', { action: 'DELETE', type: 'measure', data: { date: measure.date }});
    },

    async newActivity({dispatch}, activity){
      let response = await dispatch('updateUserData', { action: 'NEW', type: 'activity', data: activity });
      await dispatch('onActivityChange', { activity: activity, action: 'NEW' });
      return response;
    },
    async updateActivity({dispatch, state}, activity){
      let previousActivity = Object.assign({}, state.activities.find(a => a.serial == activity.serial));
      let response = await dispatch('updateUserData', { action: 'UPDATE', type: 'activity', data: activity });
      await dispatch('onActivityChange', { activity: activity, action: 'UPDATE', previousActivity: previousActivity });
      return response;
    },
    async removeActivity({dispatch}, activity){
      let response = await dispatch('updateUserData', { action: 'DELETE', type: 'activity', data: { serial_number: activity.serial_number }});
      await dispatch('onActivityChange', { activity: activity, action: 'DELETE' });
      return response;
    },
    async uploadActivity({dispatch}, data){
      var resp = await post('/upload_activity', data);
      return dispatch('updateChanges', resp);
    },


    newWorkout({dispatch}, workout){
      return dispatch('updateUserData', { action: 'NEW', type: 'workout', data: workout });
    },
    updateWorkout({dispatch}, workout){
      return dispatch('updateUserData', { action: 'UPDATE', type: 'workout', data: workout });
    },
    removeWorkout({dispatch}, workout){
      return dispatch('updateUserData', { action: 'DELETE', type: 'workout', data: { id: workout.id } });
    },


    //If objective change (added/removed/updated), regenerate week
    //TODO: if objective update without training data change (duration), for example if only name change, don't regenerate week
    async onObjectiveChange({dispatch}, { objective, action, previousObjective = {} }){
      if(!this.$app.$planExist()) //If no plan exist currently, no change to do
        return;

      let loading = this.$app.$buefy.loading.open();
      var date = objective.date;
      try{
        let changes = [];
        //If change in objective A date (New, remove, or date update), need to regenerate whole plan
        //If current route is generate training plan, don't regenerate whole plan as it will probably be regenerated later and we don't want to make the user wait
        if(objective.priority === 'A' && (action !== 'UPDATE' || objective.date != previousObjective.date || objective.profile_type !== previousObjective.profile_type) &&
          this.$app.$router.currentRoute.path != '/generate_training_plan'){

          loading.close();//close waiting for confirm result and reopen after
          const confirmResult = await new Promise((resolve, reject) => {
            this.$app.$buefy.dialog.confirm({
              title: this.$app.$t('plan.want_update'),
              message: this.$app.$t('plan.update_details'),
              cancelText: this.$app.$t('general.no'),
              confirmText: this.$app.$t('plan.update'),
              onConfirm: () => resolve(true),
              onCancel: () => resolve(false),
            });
          });
          loading = this.$app.$buefy.loading.open();
          if(confirmResult)
            changes = await post('/regenerate_training_plan');
        }else{
          changes = await post('/regenerate_week', { date: date });
          if(date.getDay() === 0 || date.getDay() > 5){ //if day is saturday or after, regenerate next week
            changes = changes.concat(await post('/regenerate_week', { date: date.addDay(7) }));
          }else if(date.getDay() < 3){ //if day is tuesday or before, regenerate previous week
            changes = changes.concat(await post('/regenerate_week', { date: date.addDay(-7) }));
          }
          trackWeekRegeneration();
        }
        dispatch('updateChanges', changes);
      }catch(err){
        console.error(err);
      }finally{
        loading.close();
      }
    },

    //If training ride change (added/removed/updated), regenerate week
    async onTrainingRideChange({dispatch}, trainingRide){
      if(!this.$app.$planExist()) //If no plan exist currently, no change to do
        return;

      const loading = this.$app.$buefy.loading.open();
      var date = trainingRide.date;
      try{
        let changes = await post('/regenerate_week', { date: date });
        if(date.getDay() === 0 || date.getDay() > 5){ //if day is saturday or after, regenerate next week
          changes = changes.concat(await post('/regenerate_week', { date: date.addDay(7) }));
        }else if(date.getDay() < 3){ //if day is tuesday or before, regenerate previous week
          changes = changes.concat(await post('/regenerate_week', { date: date.addDay(-7) }));
        }
        trackWeekRegeneration();
        dispatch('updateChanges', changes);
      }catch(err){
        console.error(err);
      }finally{
        loading.close();
      }
    },

    //If activity change with change in intensity rating, regenerate week
    async onActivityChange({dispatch}, { activity, action, previousActivity = {} }){
      if(!this.$app.$planExist()) //If no plan exist currently, no change to do
        return;

      //Ignore regeneration if activity is bound to a workout
      if(activity.bound_workout)
        return;

      if(!activity.intensity_done) //If no intensity rating, ignore
        return;

      //If it's an activity edit, but no change in activity rating, distance or load, ignore.
      if(action === 'UPDATE' && activity.date == previousActivity.date && activity.intensity_done === previousActivity.intensity_done && activity.duration === previousActivity.duration && activity.data.load === previousActivity.data.load)
        return;

      const loading = this.$app.$buefy.loading.open();
      var date = activity.date;
      try{
        let changes = await post('/regenerate_week', { date: date });
        if(date.getDay() === 0 || date.getDay() > 5){ //if day is saturday or after, regenerate next week
          changes = changes.concat(await post('/regenerate_week', { date: date.addDay(7) }));
        }else if(date.getDay() < 3){ //if day is tuesday or before, regenerate previous week
          changes = changes.concat(await post('/regenerate_week', { date: date.addDay(-7) }));
        }
        trackWeekRegeneration();
        dispatch('updateChanges', changes);
      }catch(err){
        console.error(err);
      }finally{
        loading.close();
      }
    },

    async newObjective({dispatch}, objective){
      var response = await dispatch('updateUserData', { action: 'NEW', type: 'objective', data: objective });
      await dispatch('onObjectiveChange', { objective: objective, action: 'NEW' });
      return response;
    },
    async updateObjective({dispatch, state}, objective){
      let previousObjective = Object.assign({}, state.objectives.find(o => o.id == objective.id));
      var response = await dispatch('updateUserData', { action: 'UPDATE', type: 'objective', data: objective });
      await dispatch('onObjectiveChange', { objective: objective, action: 'UPDATE', previousObjective: previousObjective });
      return response;
    },
    async removeObjective({dispatch}, objective){
      var response = await dispatch('updateUserData', { action: 'DELETE', type: 'objective', data: { id: objective.id } });
      await dispatch('onObjectiveChange', { objective: objective, action: 'DELETE' });
      return response;
    },

    async newTrainingRide({dispatch}, trainingRide){
      var response = await dispatch('updateUserData', { action: 'NEW', type: 'training_ride', data: trainingRide });
      await dispatch('onTrainingRideChange', trainingRide);
      return response;
    },
    async updateTrainingRide({dispatch}, trainingRide){
      var response = await dispatch('updateUserData', { action: 'UPDATE', type: 'training_ride', data: trainingRide });
      await dispatch('onTrainingRideChange', trainingRide);
      return response;
    },
    async removeTrainingRide({dispatch}, trainingRide){
      var response = await dispatch('updateUserData', { action: 'DELETE', type: 'training_ride', data: { id: trainingRide.id } });
      await dispatch('onTrainingRideChange', trainingRide);
      return response;
    },


    updateChanges({commit}, changes){
      const methods = {
        'NEW': {
          'activity': 'addActivity',
          'measure': 'addMeasure',
          'workout': 'addWorkout',
          'model': 'addModel',
          'objective': 'addObjective',
          'training_ride': 'addTrainingRide',
        },
        'UPDATE': {
          'activity': 'updateActivity',
          'measure': 'updateMeasure',
          'workout': 'updateWorkout',
          'model': 'updateModel',
          'objective': 'updateObjective',
          'training_ride': 'updateTrainingRide'
        },
        'DELETE': {
          'activity': 'removeActivity',
          'measure': 'removeMeasure',
          'workout': 'removeWorkout',
          'model': 'removeModel',
          'objective': 'removeObjective',
          'training_ride': 'removeTrainingRide',
        },
        'SET': {
          'model': 'setModels',
          'training_block': 'setTrainingBlocks',
        },
      };
      if(changes && Array.isArray(changes)){
        changes.forEach(change => {
          commit(methods[change.action][change.type], change.data);
        });
      }
    },


    async updateUserData({dispatch}, request){
      var resp = await post('/update_data', request);
      return dispatch('updateChanges', resp);
    },


    async bindWorkoutActivity({dispatch}, { workout, activity }){
      await post('/bind_workout_activity', { workout_id: workout.id, activity_serial_number: activity.serial_number });
      Vue.set(workout, 'bound_activity', activity.serial_number);
      Vue.set(activity, 'bound_workout', workout.id);
    },

    async unbindWorkoutActivity({dispatch}, { workout, activity }){
      await post('/unbind_workout_activity', { workout_id: workout.id, activity_serial_number: activity.serial_number });
      Vue.delete(workout, 'bound_activity');
      Vue.delete(activity, 'bound_workout');
    },


    async bindTrainingRideActivity({dispatch}, { training_ride, activity }){
      await post('/bind_training_ride_activity', { training_ride_id: training_ride.id, activity_serial_number: activity.serial_number });
      Vue.set(training_ride, 'bound_activity', activity.serial_number);
      Vue.set(activity, 'bound_training_ride', training_ride.id);
    },

    async unbindTrainingRideActivity({dispatch}, { training_ride, activity }){
      await post('/unbind_training_ride_activity', { training_ride_id: training_ride.id, activity_serial_number: activity.serial_number });
      Vue.delete(training_ride, 'bound_activity');
      Vue.delete(activity, 'bound_training_ride');
    },


    setLoadedUser({commit, dispatch}, user_id){
      commit('setLoadedUser', user_id);
      return dispatch('loadUserData');
    },

    async updateTrainingPlanData({commit, dispatch}, training_plan_data){
      var changes = await post('/update_training_plan_data', { training_plan_data: training_plan_data });
      commit('set_training_plan_data', training_plan_data);
      dispatch('updateChanges', changes);
    },
    async regenerateTrainingPlan({commit, dispatch}, training_plan_data){
      var changes = await post('/regenerate_training_plan', { training_plan_data: training_plan_data });
      commit('set_training_plan_data', training_plan_data);
      dispatch('updateChanges', changes);
    },
    async regenerateWorkouts({commit, dispatch}){
      var changes = await post('/regenerate_workouts');
      dispatch('updateChanges', changes);
    },
    async generateTrainingPlan({commit, dispatch}, training_plan_data){
      var changes = await post('/generate_training_plan', { training_plan_data: training_plan_data });
      commit('set_training_plan_data', training_plan_data);
      dispatch('updateChanges', changes);
    },
    async regenerateWeek({commit, dispatch}, week){
      var changes = await post('/regenerate_week', { date: week });
      dispatch('updateChanges', changes);
      trackWeekRegeneration();
    },
    async deleteTrainingPlan({commit, dispatch}){
      var changes = await post('/delete_training_plan');
      dispatch('updateChanges', changes);
    },


    async updateSetModel({commit}, set_model){
      await post('/update_set_model', { set_model: set_model });
      commit('setSetModel', set_model);
    },
  },
  getters : {
    activities: state => state.activities,
    measures: state => state.measures,
    models: state => state.models,
    workouts: state => state.workouts,
    objectives: state => state.objectives,
    trainingRides: state => state.trainingRides,
    trainingBlocks: state => state.trainingBlocks,
    loadingStatus: state => state.loadingStatus,
    loadedUser: state => state.loadedUser,
    set_model: state => state.set_model,
  }
}

const loadedUser = localStorage.getItem('loadedUser');
if(loadedUser)
  axios.defaults.headers.common['v-loaded-user'] = loadedUser;


export default userDataModule;
