<template>
  <div class="feedback card" v-if="isActive">
    <header class="card-header">
      <h2 class="card-header-title">{{ $t('feedback.title') }}</h2>
    </header>
    <div class="card-content">
      <vue-feedback-reaction v-model="feedback" :is-active="true"/>
    </div>
    <footer class="card-footer">
      <b-button @click="close()">{{ $t('general.close') }}</b-button>
    </footer>
  </div>
</template>

<script>
import { VueFeedbackReaction } from 'vue-feedback-reaction';

export default{
  name: 'Feedback',
  data(){
    return {
      feedback: '',
      isActive: false,
    };
  },
  methods: {
    close(){
      this.isActive = false;
    },
  },
  components: {
    VueFeedbackReaction
  },
};
</script>

<style lang="scss" scoped>

.feedback{
  /*background-color: #fff;
  box-shadow: 0 0 1em rgba(0,0,0,.1);
  padding: 1em 1.5em;*/
  position: fixed;
  z-index: 60;
  bottom: 10em;
  right: 1em;
}
</style>
